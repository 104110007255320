import {
    IonAlert,
    IonAvatar,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonChip,
    IonCol,
    IonContent, IonFooter,
    IonGrid,
    IonHeader,
    IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions,
    IonItemSliding, IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonModal,
    IonNote,
    IonPage, IonReorder, IonReorderGroup, IonRippleEffect, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonText,
    IonTitle,
    IonToolbar, useIonAlert, useIonPopover
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import {useFirestoreDocData, useFirestore, SuspenseWithPerf} from "reactfire";
import React, {Fragment, Suspense, useContext, useEffect, useRef, useState} from "react";
import AuthContext from '../auth-context'
import {
    reloadOutline,
    addOutline,
    enterOutline,
    informationCircleOutline,
    menuOutline,
    checkmarkCircleOutline,
    home,
    removeCircleOutline, card
} from "ionicons/icons";
import HeaderContainer from "../components/Header";
import {RouteComponentProps} from "react-router";
import useEvents from "../hooks/events-provider";
import useEvent from "../hooks/event-provider";
import useCards from "../hooks/card-provider";

interface ManageScoresPageProps extends RouteComponentProps<{
    id: string;
}> {
}

function HoleScoresHelpModal({closeModalHandler, showModal}: any) {
    const closeHandler = () => {
        closeModalHandler();
    }

    return <IonModal isOpen={showModal}>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Help With Hole Assignments</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={closeHandler}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className="ion-padding" fullscreen>
                <IonText>
                    <IonNote>TODO</IonNote>
                    {/*<h4>Initial setup</h4>*/}
                    {/*<ol>*/}
                    {/*    <li>Select the desired 'Players Per Card'. This defaults to 4.</li>*/}
                    {/*    <li>Click 'Shuffle' - This will randomly assign players into groups.</li>*/}
                    {/*</ol>*/}
                    {/*<h4>Changing Groups</h4>*/}
                    {/*<p>You can move players between groups by clicking a player, then clicking where you want to move*/}
                    {/*    them to.</p>*/}
                </IonText>
            </IonContent>
        </IonHeader>
    </IonModal>
}

function CtpModal({closeModalHandler, showModal, ctp}: any) {
    const closeHandler = () => {
        closeModalHandler();
    }

    return <IonModal isOpen={showModal}>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>CTP for hole {ctp && ctp.hole}</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={closeHandler}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className="ion-padding" fullscreen>
                <IonText>
                    <IonNote>TODO</IonNote>
                    {/*<h4>Initial setup</h4>*/}
                    {/*<ol>*/}
                    {/*    <li>Select the desired 'Players Per Card'. This defaults to 4.</li>*/}
                    {/*    <li>Click 'Shuffle' - This will randomly assign players into groups.</li>*/}
                    {/*</ol>*/}
                    {/*<h4>Changing Groups</h4>*/}
                    {/*<p>You can move players between groups by clicking a player, then clicking where you want to move*/}
                    {/*    them to.</p>*/}
                </IonText>
            </IonContent>
        </IonHeader>
    </IonModal>
}

function SelectCtpWinnerModal({selectPlayerHandler, closeModalHandler, showModal, eventId}: any) {
    const [{event, players}, {saveCtp}]: any = useEvent(eventId);
    const [searchText, setSearchText] = useState('');
    const searchRef = useRef<HTMLIonSearchbarElement>();

    useEffect(() => {
        searchRef.current?.focus();
    }, [])

    const closeHandler = () => {
        setSearchText('');
        closeModalHandler();
    }

    const selectHandler = (player: any) => {
        setSearchText('');

        selectPlayerHandler(player)
    }
    return <IonModal isOpen={showModal}>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Select Player</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={closeHandler}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className="ion-padding" fullscreen>
                <IonSearchbar inputMode="text" value={searchText} onIonChange={e => setSearchText(e.detail.value!)}/>
                <IonList>
                    {
                        players && players
                            //@ts-ignore
                            .filter((x: any) => x.firstName.toLowerCase().includes(searchText.toLowerCase()) || x.lastName.toLowerCase().includes(searchText.toLocaleLowerCase())).map((item: any, index: number) => {
                                    return (
                                        <IonItem key={item.playerId} onClick={() => selectHandler(item)}>
                                            <IonLabel>{item.firstName} {item.lastName}</IonLabel>
                                        </IonItem>
                                    )
                                }
                            )}
                </IonList>
            </IonContent>
        </IonHeader>
    </IonModal>
}

const ManageScores: React.FC<ManageScoresPageProps> = ({match, history}) => {
    const {authValues} = useContext(AuthContext);
    const [{event, players, ctps}, {savePlayer, saveCtp}]: any = useEvent(match.params.id);
    // @ts-ignore
    const [cards, {saveCard}] = useCards(match.params.id);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showCtpModal, setShowCtpModal] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState<any>();
    const [selectedCtp, setSelectedCtp] = useState<any>();
    const [playerIndex, setPlayerIndex] = useState<any>();
    const [selectedCard, setSelectedCard] = useState<any>();
    const [showAlert, setShowAlert] = useState(false);

    const closeModalHandler = () => {
        setShowHelpModal(false);
    }

    const showHelp = () => {
        setShowHelpModal(true);
    }

    const selectCtpModalHandler = (player: any) => {
        setShowCtpModal(false);
        selectedCtp.winner = player;
        saveCtp(selectedCtp);
    }

    const closeCtpModalHandler = () => {
        setShowCtpModal(false);
    }

    const showCtp = (ctp: any) => {
        if (event.final || !authValues.isCoordinator) {
            return;
        }
        setSelectedCtp(ctp);
        setShowCtpModal(true);
    }

    const openAlert = (player: any, player_index: number, card: any) => {
        if (event.final || !authValues.isCoordinator) {
            return;
        }
        setSelectedPlayer(player);
        setPlayerIndex(player_index);
        setSelectedCard(card);
        // setShowHoleAssignmentModal(true);
        setShowAlert(true);
    }

    const scoreSubmitHandler_doubles = (value: any) => {
        // const player_handicap = selectedPlayer.handicaps.find((x: any) => x.courseId == event.course_id).handicap;
        // const adjusted_score: number = (+value.score + +player_handicap);

        // Set card values
        selectedCard.players[playerIndex].rawScore = value.score;
        // selectedCard.players[playerIndex].handicap = player_handicap;
        // selectedCard.players[playerIndex].adjustedScore = adjusted_score;

        // @ts-ignore
        saveCard(selectedCard);
        setShowAlert(false);
    }
    
    const scoreSubmitHandler_handicaps = (value: any) => {
        const player_handicap = selectedPlayer.handicap != undefined ? selectedPlayer.handicap : null;
        const adjusted_score: number = (+value.score + +player_handicap);

        // Set card values
        selectedCard.players[playerIndex].rawScore = value.score;
        selectedCard.players[playerIndex].handicap = player_handicap;
        selectedCard.players[playerIndex].adjustedScore = player_handicap != null ? adjusted_score : null;

        // @ts-ignore
        saveCard(selectedCard);
        setShowAlert(false);
    }
    const getPlayerHandicap = (player: any) => {
        return player.handicap;
    }

    const handicap_text = (handicap: any) => {
        return (handicap != undefined ? <IonChip>Handicap: {handicap}</IonChip> : <IonChip>Handicap:&nbsp;<i>est</i></IonChip>)
    }

    const ctp_text = (ctp: any) => {
        if (ctp.winner) {
            return (<IonItem key={ctp.id} onClick={() => showCtp(ctp)}>
                Hole {ctp.hole}
                <IonChip slot="end">{ctp.winner && ctp.winner.firstName} {ctp.winner && ctp.winner.lastName}</IonChip>
            </IonItem>)
        } else {
            return (<IonItem key={ctp.id} onClick={() => showCtp(ctp)}>
                Hole {ctp.hole}
            </IonItem>)
        }
    }

    const scoreElement_doubles = (group: any, card: any, player_index: number) => {
        if (group.rawScore) {
            return (
                <IonItem onClick={() => openAlert(group, player_index, card)}
                         key={Math.random()}>
                    <IonLabel className="ion-text-wrap">
                        {group && group.playerOne.firstName} {group && group.playerOne.lastName} {group && group.playerTwo && "/"} {group && group.playerTwo && group.playerTwo.firstName} {group && group.playerTwo && group.playerTwo.lastName} {group && !group.playerTwo && "- ODD MAN"}
                    </IonLabel>
                    <IonChip slot="end">{group.rawScore}</IonChip>
                </IonItem>)
        }
        return <IonItem onClick={() => openAlert(group, player_index, card)}
                        key={Math.random()}>
            {group && group.playerOne.firstName} {group && group.playerOne.lastName} {group && group.playerTwo && "/"} {group && group.playerTwo && group.playerTwo.firstName} {group && group.playerTwo && group.playerTwo.lastName} {group && !group.playerTwo && "- ODD MAN"}
        </IonItem>;
    }

    const scoreElement_handicaps = (player: any, card: any, player_index: number) => {
        if (player.rawScore) {
            return (
                <IonItem onClick={() => openAlert(player, player_index, card)}
                         key={player.playerId}>
                    <IonLabel>
                        {player && player.firstName} {player && player.lastName}
                        <p>
                            <IonChip>Raw: {player.rawScore}</IonChip>
                            {handicap_text(player.handicap)}
                            {player.handicap != undefined && player.adjustedScore &&
                            <IonChip>Total: {player.adjustedScore}</IonChip>}
                        </p>
                    </IonLabel>
                </IonItem>)
        }
        return <IonItem onClick={() => openAlert(player, player_index, card)}
                        key={player.playerId}>{player && player.firstName} {player && player.lastName}</IonItem>;
    }
    
    const resolve_header = () => {
        if (!selectedPlayer){
            return;
        }
        if (event.type == "Doubles"){
            if (selectedPlayer.playerTwo){
                return `${selectedPlayer.playerOne.firstName} ${selectedPlayer.playerOne.lastName} / ${selectedPlayer.playerTwo.firstName} ${selectedPlayer.playerTwo.lastName}`
            }
            return `${selectedPlayer.playerOne.firstName} ${selectedPlayer.playerOne.lastName} - ODD MAN`
        }
        return  `${selectedPlayer.firstName} ${selectedPlayer.lastName}`
    }
    
    const doubles_header = () => {
        
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/manage/details/${match.params.id}`}/>
                    </IonButtons>
                    <IonTitle size="large">Record Scores</IonTitle>
                    {
                        !event.final && authValues.isCoordinator &&
                        <IonButtons slot="end">
                            <IonButton onClick={(e) => setShowHelpModal(true)} type="button" slot="end">
                                <IonIcon icon={informationCircleOutline}/>
                            </IonButton>
                        </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header= {resolve_header()}
                    inputs={[
                        {
                            attributes: {
                                autofocus: 'true'
                            },
                            name: 'score',
                            type: 'number',
                            min: '1',
                            placeholder: 'Enter Raw Score'
                        }
                    ]}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                setShowAlert(false)
                            }
                        },
                        {
                            text: 'Submit',
                            handler: (event.type === "Doubles" ? scoreSubmitHandler_doubles : scoreSubmitHandler_handicaps)
                        }
                    ]}
                />
                {
                    !event.final && authValues.isCoordinator &&
                    <IonTitle className="ion-padding">Click a player to record score</IonTitle>
                }
                {
                    !event.final && authValues.isCoordinator &&
                    <HoleScoresHelpModal showModal={showHelpModal} closeModalHandler={closeModalHandler}/>
                }
                {
                    !event.final && authValues.isCoordinator &&
                    <SelectCtpWinnerModal selectPlayerHandler={selectCtpModalHandler} eventId={match.params.id}
                                          closeModalHandler={closeCtpModalHandler}
                                          showModal={showCtpModal}/>
                }
                <Suspense fallback={'loading score details...'}>
                    <IonCard>
                        <IonItem>
                            <IonTitle>CTPs</IonTitle>
                        </IonItem>
                        <IonCardContent>
                            {
                                ctps && ctps.map((ctp: any, index: number) => {
                                        return ctp_text(ctp)
                                    }
                                )
                            }
                        </IonCardContent>
                    </IonCard>
                    {
                        // @ts-ignore
                        cards && cards.map((card: any, index: number) => {
                                return (
                                    <IonCard key={index}>
                                        <IonItem key={card.id}>
                                            <IonTitle>{card.name}</IonTitle>
                                            {card.holeAssignment &&
                                            <IonBadge slot="end">Hole {card.holeAssignment}</IonBadge>}
                                            {/*<IonIcon onClick={() => addSlot(index)} icon={addOutline}/>*/}
                                        </IonItem>
                                        <IonCardContent>
                                            {
                                                card && card.players && card.players.map((player: any, i: number) => {
                                                        return event.type === "Doubles" ? scoreElement_doubles(player, card, i) : scoreElement_handicaps(player, card, i)
                                                    }
                                                )
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                )
                            }
                        )
                    }
                </Suspense>
            </IonContent>
            {
                !event.final && authValues.isCoordinator &&
                <IonFooter>
                    <IonToolbar>
                        <IonTitle>All Done?</IonTitle>
                        <IonButtons slot="end">
                            <IonButton routerDirection="back"
                                       routerLink={`/manage/details/${match.params.id}`}>Done</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            }
        </IonPage>
    );
};

export default ManageScores;
