import React, { useCallback } from 'react';
import {useFirestoreCollectionData, useFirestore, useFirestoreDocData} from 'reactfire';
import firebase from "firebase";
import axios from "axios";
import useTenantProvider from "./tenant-provider";

interface Event {
    id: string,
    name: string,
    final: boolean,
    course_id: string,
    date: string,
    players: any
}

function useEvent<IEvent>(event_id: any)  {
    const tenant = useTenantProvider();
    // lazy load the Firestore SDK
    const ref = useFirestore().collection(`tenants/${tenant}/event`).doc(event_id);
    const player_ref = ref.collection('players');
    const cards_ref = ref.collection('cards');
    const ctps_ref = ref.collection('ctps');

    // subscribe to the collection
    const { data: event } = useFirestoreDocData(ref, {idField: 'id'});
    const { data: players } = useFirestoreCollectionData(player_ref.orderBy('firstName', 'asc'), {idField: 'id'});
    const { data: cards } = useFirestoreCollectionData(cards_ref, {idField: 'id'});
    const { data: ctps } = useFirestoreCollectionData(ctps_ref, {idField: 'id'});
    
    // create handlers
    async function addPlayer(playerData: any){
        await ref.collection('players').add(playerData);
    }
    async function removePlayer(player_id: any){
        await ref.collection('players').doc(player_id).delete();
    }
    async function removePlayerByPlayerId(player_id: any){
        await ref.collection('players').where('playerId', '==', player_id).get().then((result) => {
            result.docs[0].ref.delete();
        });
    }
    async function savePlayer(player: any){
        await player_ref.doc(player.id).set(player, { merge: true });
    }
    async function saveCtp(ctp: any){
        await ctps_ref.doc(ctp.id).set(ctp, { merge: true });
    }
    async function saveField(field: {}){
        await ref.update(field);
    }


    const groupPlayersByScoreHandicaps = (handicaps: any) => {
        let players_with_score: any[] = [];
        let final_payout_local: any[] = [];

        // Count people who are NOT establishing in this event by virtue of having a handicap for the course.
        // @ts-ignore
        const playersWithHandicap = players.filter(x => x.handicap != null);
        const payoutCount: number = playersWithHandicap.length

        // get the payout object for the amount of people we have in the pool.
        // @ts-ignore
        let payout_local: any = handicaps.find(x => x.players == payoutCount);

        if (!payout_local) {
            payout_local = {
                'players': payoutCount,
                'payouts': [
                    {
                        'place': 1, 'amount': (payoutCount * 5)
                    }
                ]
            }
        }

        cards.forEach((card: any, index: number) => {
            let p = card.players.filter((x: any) => x.handicap != null);

            p.forEach((player: any, i: number) => {
                players_with_score.push(player);
            });
        })

        // sort players by adjusted score
        players_with_score.sort((a, b) => (a.adjustedScore > b.adjustedScore) ? 1 : -1);

        // check before and after for same score, if so give it a Tied marker
        let place = 1;
        players_with_score.forEach((player: any, index: number) => {
            var player_payout_obj = {
                "firstName": player.firstName,
                "lastName": player.lastName,
                "place": place.toString(),
                "rawScore": player.rawScore,
                "handicap": player.handicap,
                "handicap_absolute_value": Math.abs(player.handicap),
                "handicap_sign": player.handicap >= 0 ? "+" : "-",
                "adjustedScore": player.adjustedScore,
                "amount": "",
                "tied": false
            }
            let previous_player = players_with_score[index - 1];
            let next_player = players_with_score[index + 1];

            // Previous player has a matching adjusted score
            if (previous_player && previous_player.adjustedScore == player.adjustedScore || next_player && next_player.adjustedScore == player.adjustedScore) {
                player_payout_obj.tied = true;
                player_payout_obj.place = `T${place}`
            }

            let payout_amt = payout_local.payouts[index];
            if (payout_amt) {
                player_payout_obj.amount = payout_amt.amount;
            }

            // increment place if next player is not tied
            if (next_player && next_player.adjustedScore != player.adjustedScore) {
                // setting up place for the next player... can probably do this elsewhere to make it cleaner
                place = index + 2;
            }

            final_payout_local.push(player_payout_obj);
        })
        return final_payout_local;
    }
    
    const groupPlayersByScoreDoubles = (doubles: any) => {

        let players_with_score: any[] = [];
        let final_payout_local: any[] = [];

        // @ts-ignore
        const payoutCount: number = players.length + (event.oddman_paid_extra ? 1 : 0);

        // get the payout object for the amount of people we have in the pool.
        let payout_local: any = doubles.find((x: any) => x.players == payoutCount);

        if (!payout_local) {
            payout_local = {
                'players': payoutCount,
                'payouts': [
                    {
                        'place': 1, 'amount': (payoutCount * 5)
                    }
                ]
            }
        }

        cards.forEach((card: any, index: number) => {
            card.players.forEach((player: any, i: number) => {
                players_with_score.push(player);
            });
        })

        // sort players by RAW score
        players_with_score.sort((a, b) => (a.rawScore > b.rawScore) ? 1 : -1);

        // check before and after for same score, if so give it a Tied marker
        let place = 1;
        players_with_score.forEach((player: any, index: number) => {
            var player_payout_obj = {
                "playerOne": player.playerOne,
                "playerTwo": player.playerTwo,
                "place": place.toString(),
                "rawScore": player.rawScore,
                "amount": "",
                "tied": false
            }
            let previous_player = players_with_score[index - 1];
            let next_player = players_with_score[index + 1];

            // Previous player has a matching adjusted score
            if (previous_player && previous_player.rawScore == player.rawScore || next_player && next_player.rawScore == player.rawScore) {
                player_payout_obj.tied = true;
                player_payout_obj.place = `T${place}`
            }

            let payout_amt = payout_local.payouts[index];
            if (payout_amt) {
                player_payout_obj.amount = payout_amt.amount;
            }

            // increment place if next player is not tied
            if (next_player && next_player.rawScore != player.rawScore) {
                // setting up place for the next player... can probably do this elsewhere to make it cleaner
                place = index + 2;
            }

            final_payout_local.push(player_payout_obj);
        })
        
        return final_payout_local;

    }

    // This method prepares the well-formed event object that the stats app is expecting.
    const prepareEventResults = (event: any, cards: any, ctps: any) => {
        const handicapRoundDto: any[] = [];
        const doublesRoundDto: any[] = [];
        var ctpsArray: any[] = [];
        const aceDto = [];

        cards.forEach((card: any, index: any) => {
            // Handicaps
            if (event.type == 'Handicaps'){
                card.players.forEach((player: any, i: number) => {
                    handicapRoundDto.push({
                        'playerId': player.playerId,
                        'rawScore': player.rawScore,
                        'handicap': player.handicap,
                        'adjustedScore': player.adjustedScore
                    })
                })
            }
            
            if (event.type == 'Scratch'){
                card.players.forEach((player: any, i: number) => {
                    handicapRoundDto.push({
                        'playerId': player.playerId,
                        'rawScore': player.rawScore
                    })
                })
            }
            
            if (event.type == 'Doubles'){
                card.players.forEach((group: any, i: number) => {
                    var dto = {
                        'rawScore': group.rawScore,
                        'players': [
                            group.playerOne.playerId
                        ]
                    }
                    if (group.playerTwo != undefined) {
                        dto.players.push(group.playerTwo.playerId)
                    }
                    doublesRoundDto.push(dto);
                })
            }
        })
        
        ctps.forEach((ctp: any, index: number) => {
            ctpsArray.push({
                'hole': ctp.hole,
                'player': ctp.winner
            });
        })

        // the server expects the type 'Handicap', instead of refactoring it everywhere I'm making the change here only.
        var event_type = event.type;
        if (event_type == "Handicaps") {
            event_type = "Handicap";
        }

        const eventDto = {
            'title': event.name,
            'courseId': event.course_id,
            'isWarQualifier': event.is_war_qualifier,
            'date': event.date,
            'type': event_type,
            'players': handicapRoundDto,
            'doublesGroups': doublesRoundDto,
            'ctps': ctpsArray
        }
        return eventDto;
    }

    const prepareFacebookResults = (event:any) => {
        const doublesRoundDto: any[] = [];
        var ctpsArray: any[] = [];
        const aceDto: any[] = [];

        const event_date = new Date(event.date).toDateString();
        let handicapResults: any[] = [];
        let message = `${event.name} results from ${event_date}\r\n\r\n${players.length} players showed up to compete.\r\n\r\n`;
        
        let ctp_text = 'CTPs';
        ctps.forEach((ctp: any, index: number) => {
            if (!ctp.winner){
                return;
            }
            ctp_text += `\r\nHole ${ctp.hole}: ${ctp.winner.firstName} ${ctp.winner.lastName}`
        })
        
        if (event.type == 'Doubles'){
            const doublesResult = groupPlayersByScoreDoubles([])
            
            let groups_with_score_text = '';
            doublesResult.forEach((group: any, index:number) => {
                groups_with_score_text += `\r\n${group.place} - ${group.playerOne.firstName} ${group.playerOne.lastName} / ${group.playerTwo.firstName} ${group.playerTwo.lastName} scored a ${group.rawScore}`;
            })

            message += ctp_text;
            message += '\r\n'
            message += groups_with_score_text;
        }

        if (event.type == 'Handicaps'){
            handicapResults = groupPlayersByScoreHandicaps([])
            
            let establishingPlayers: any[] = [];
            // tack on players who have no handicap
            cards.forEach((card:any, index:number) => {
                const playersWithoutHandicap = card.players.filter((x: any) => x.handicap == null);
                
                playersWithoutHandicap.forEach((player: any, i: number) => {
                    establishingPlayers.push(player)
                })
            })
            
            let players_with_handicap_text = ''
            handicapResults.forEach((round: any, index:number) => {
                players_with_handicap_text += `\r\n${round.place} - ${round.firstName} ${round.lastName}: ${round.rawScore} ${round.handicap_sign} ${round.handicap_absolute_value} = ${round.adjustedScore}`
            });
            
            // Sort the establishing players
            establishingPlayers.sort((a, b) => (a.rawScore > b.rawScore) ? 1 : -1);
            
            let establishing_players_text = '';
            establishingPlayers.forEach((round: any, index: number) => {
                establishing_players_text += `\r\n${round.firstName} ${round.lastName}: ${round.rawScore}`
            })
            
            message += ctp_text;
            message += '\r\n'
            message += players_with_handicap_text;
            message += '\r\n\r\nEstablishing Players'
            message += establishing_players_text;
        }

        if (event.type == 'Scratch'){

            let playerRounds: any[] = [];
            // tack on players who have no handicap
            cards.forEach((card:any, index:number) => {
                card.players.forEach((player: any, i: number) => {
                    playerRounds.push(player)
                })
            })
            
            // Sort the player rounds
            playerRounds.sort((a, b) => (a.rawScore > b.rawScore) ? 1 : -1);

            let player_text = ''
            playerRounds.forEach((round: any, index:number) => {
                player_text += `\r\n${round.firstName} ${round.lastName}: ${round.rawScore}`
            });


            message += ctp_text;
            message += '\r\n'
            message += player_text;
        }

        // cards.forEach((card: any, index: any) => {
        //     if (event.type == 'Doubles'){
        //         card.players.forEach((group: any, i: number) => {
        //             var dto = {
        //                 'rawScore': card.rawScore,
        //                 'players': [
        //                     group.playerOne.playerId
        //                 ]
        //             }
        //             if (group.playerTwo != undefined) {
        //                 dto.players.push(group.playerTwo.playerId)
        //             }
        //             doublesRoundDto.push(dto);
        //         })
        //     }
        // })
        //
        // ctps.forEach((ctp: any, index: number) => {
        //     ctpsArray.push({
        //         'hole': ctp.hole,
        //         'player': ctp.winner
        //     });
        // })

        // const facebookDto = {
        //     'title': event.name,
        //     'courseId': event.course_id,
        //     'isWarQualifier': event.is_war_qualifier,
        //     'date': event.date,
        //     'type': event.type,
        //     'handicapResults': handicapResults,
        //     'doublesResults': doublesRoundDto,
        //     'ctps': ctpsArray,
        //     'aces': aceDto
        // }
        
        // Prepare the message
        
        return message;
    }

    async function postToFacebook(event: any) {
        // build out the Event DTO
        const messageDto = {
            'message': prepareFacebookResults(event)
        }

        const userToken = await firebase.auth().currentUser?.getIdToken();
        const requestOptions = {
            headers: {'Content-Type': 'application/json', 'Authorization': userToken}
        };

        // console.log(messageDto.message);
        return axios.post(`api/event/facebook`, messageDto, requestOptions)
    }
    
    async function finalize(event: any, cards: any, ctps: any) {
        // build out the Event DTO
        var evDto = prepareEventResults(event, cards, ctps);
        
        const userToken = await firebase.auth().currentUser?.getIdToken();
        const requestOptions = {
            headers: {'Content-Type': 'application/json', 'Authorization': userToken}
        };
        
        return axios.post(`api/event/finalize`, evDto, requestOptions)
    }

    const handlers = {
        addPlayer: useCallback(addPlayer, [ref]),
        savePlayer: useCallback(savePlayer, [ref]),
        saveCtp: useCallback(saveCtp, [ref]),
        removePlayer: useCallback(removePlayer, [ref]),
        saveField: useCallback(saveField, [ref]),
        finalize: useCallback(finalize, [ref]),
        postToFacebook: useCallback(postToFacebook, [ref]),
        groupPlayersByScoreHandicaps: useCallback(groupPlayersByScoreHandicaps, [ref]),
        groupPlayersByScoreDoubles: useCallback(groupPlayersByScoreDoubles, [ref]),
        removePlayerByPlayerId: useCallback(removePlayerByPlayerId, [ref])
    }

    // return array of [data, handlers] to match hooks like useState
    return [{event, players, cards, ctps}, handlers];
}

export default useEvent;