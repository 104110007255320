import React from 'react';
import firebase from "firebase";
import ErrorBoundary from "./components/errorboundary";

// create the context
export const Context = React.createContext<any>(undefined);


// Create the context provider, we are using state to ensure that
// we get reactive values from the context...

export const AuthProvider: React.FC = ({children}) => {
    // the reactive values
    const [authValues, setAuthValues] = React.useState<any>({
        authenticated: false,
        user: null,
        userInfo: null,
        errors: null,
        initialized: false
    });

    const login = (user: firebase.User) => {
        // Check claims and set appropriately.
        user.getIdTokenResult(true) // 1
            .then((idTokenResult) => {
                // 2 Confirm the user is an Admin
                setAuthValues({
                    authenticated: true,
                    user: user,
                    initialized: true,
                    isCoordinator: idTokenResult.claims.coordinator || false
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const logout = async () => {
        await firebase.auth().signOut();
        setAuthValues({
            authenticated: false,
            user: null,
            userInfo: null,
            isCoordinator: false
        })
        return Promise.resolve(true);
    }

    const initialize = () => {
        let unsub = firebase.auth().onAuthStateChanged(async (authUser: firebase.User | null) => {
            if (authUser) {
                let isCoordinator = false;
                // Get claims for user to conditionally control UI
                var token = await authUser.getIdTokenResult(true) // 1

                setAuthValues({
                    authenticated: true,
                    isCoordinator: token.claims.coordinator,
                    user: authUser,
                    userInfo: null,
                    errors: null,
                    initialized: true
                })
            } else {
                setAuthValues({
                    initialized: true,
                    user: null,
                    isCoordinator: false
                })
            }

            // stop listening
            await unsub();
        })
    }

    // the store object
    let state = {
        authValues: authValues,
        initialize,
        login,
        logout
    }

    return <ErrorBoundary fallback='Error with Context Provider....'><Context.Provider
        value={state}>{children}</Context.Provider></ErrorBoundary>
}

export default Context;