import {
    IonAvatar,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent, IonFooter,
    IonGrid,
    IonHeader,
    IonIcon, IonItem, IonItemOption, IonItemOptions,
    IonItemSliding, IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonModal,
    IonNote,
    IonPage, IonReorder, IonReorderGroup, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonText,
    IonTitle,
    IonToolbar, useIonPopover
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import {useFirestoreDocData, useFirestore, SuspenseWithPerf} from "reactfire";
import React, {Fragment, Suspense, useContext, useEffect, useRef, useState} from "react";
import AuthContext from '../auth-context'
import {
    reloadOutline,
    addOutline,
    enterOutline,
    informationCircleOutline,
    menuOutline,
    checkmarkCircleOutline,
    home,
    removeCircleOutline, card
} from "ionicons/icons";
import HeaderContainer from "../components/Header";
import {RouteComponentProps} from "react-router";
import useEvents from "../hooks/events-provider";
import useEvent from "../hooks/event-provider";
import useCards from "../hooks/card-provider";
import {ItemReorderEventDetail} from "@ionic/core";
import usePlayers from "../hooks/player-provider";
import firebase from 'firebase';

interface ManageCardsPageProps extends RouteComponentProps<{
    id: string;
}> {
}

function CardsHelpModal({closeModalHandler, showModal}: any) {
    const closeHandler = () => {
        closeModalHandler();
    }

    return <IonModal isOpen={showModal}>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Help With Cards</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={closeHandler}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className="ion-padding" fullscreen>
                <IonText>
                    <IonNote>The cards page is where you will organize players into groups.</IonNote>
                    <h4>Initial setup</h4>
                    <ol>
                        <li>Select the desired 'Players Per Card'. This defaults to 4.</li>
                        <li>Click 'Shuffle' - This will randomly assign players into groups.</li>
                    </ol>
                    <h4>Changing Groups</h4>
                    <p>You can move players between groups by clicking a player, then clicking where you want to move
                        them to.</p>
                </IonText>
            </IonContent>
        </IonHeader>
    </IonModal>
}

const PopoverList: React.FC<{
    onHide: () => void;
    shuffleCards: () => void;
    addCard: () => void;
    showHelp: () => void;
}> = ({onHide, shuffleCards, addCard, showHelp}) => {
    
    const handleShuffleCards = () => {
        onHide();
        shuffleCards();
    }
    const handleAddCard = () => {
        onHide();
        addCard();
    }
    const handleShowHelp = () => {
        onHide();
        showHelp();
    }

    return (
        <IonList>
            <IonItem button onClick={handleAddCard}>Add Card</IonItem>
            <IonItem button onClick={handleShowHelp}>Show Help</IonItem>
            <IonItem button onClick={handleShuffleCards}>Shuffle Cards</IonItem>
            <IonItem lines="none" detail={false} button onClick={onHide}>
                Close
            </IonItem>
        </IonList>
    )
};

const ManageCards: React.FC<ManageCardsPageProps> = ({match, history}) => {
    const {authValues} = useContext(AuthContext);
    const [{event, players}, {saveField}]: any = useEvent(match.params.id);
    // @ts-ignore
    const [cards, {addCard, saveCard, addPlayerToCard, deleteCard}] = useCards(match.params.id);
    // const [_cards, set_cards] = useState<any>([]);
    const [ppc, setPpc] = useState(4);
    const [selectedPlayer, setSelectedPlayer] = useState<any>(null);
    const [showHelpModal, setShowHelpModal] = useState(false);

    useEffect(() => {
        // Detect and set processing state
        //@ts-ignore
        if (!event.final && authValues.isCoordinator) {
            saveField({"manageCardsProcessed": "processing"});
        }
    }, [])
    
    const addEmptyCard = () => {
        addCard({
            // @ts-ignore
            'ordinal': cards.length,
            // @ts-ignore
            'name': `Card ${cards.length + 1}`,
            'players': []
        })
    }

    const handleDoneClick = () => {
        saveField({"manageCardsProcessed": true});
    }
    
    const closeModalHandler = () => {
        setShowHelpModal(false);
    }

    function shuffle(array: any) {
        var currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const makeCards = () => {
        let card_count = Math.ceil(players.length / ppc);
        let new_cards: any[] = [];
        // Setup empty cards
        for (let i = 0; i < card_count; i++) {
            new_cards.push([]);
        }

        // shuffle cards
        const shuffled_players = shuffle(players);

        // assign players to card
        let c = 0;
        // Populate cards fully so there are empty spots to be filled
        for (let i = 0; i < new_cards.length * ppc; i++) {
            // @ts-ignore
            if (shuffled_players[i]) {
                new_cards[c].push(shuffled_players[i])
            }

            if ((i + 1) % ppc == 0) {
                c++;
            }
        }

        // nuke existing cards in firebase
        if (cards) {
            // @ts-ignore
            cards.forEach((card: any) => {
                deleteCard(card.id);
            })
        }

        // persist cards to firebase
        new_cards.forEach((item, index) => {
            addCard({
                'ordinal': index,
                'name': `Card ${index + 1}`,
                'players': new_cards[index]
            })
        })
    }
    
    const showHelp = () => {
        setShowHelpModal(true);
    }

    const [present, dismiss] = useIonPopover(PopoverList, {
        onHide: () => dismiss(),
        addCard: addEmptyCard,
        shuffleCards: makeCards,
        showHelp: showHelp
    })

    const processAddPlayerToCard = (target_card: number) => {
        if (!selectedPlayer) {
            return;
        }

        // move player to new card
        // @ts-ignore
        cards[target_card].players.push(cards[selectedPlayer.card].players[selectedPlayer.slot]);

        // remove player from old card
        // @ts-ignore
        cards[selectedPlayer.card].players.splice(selectedPlayer.slot, 1);
        setSelectedPlayer(null);

        // @ts-ignore
        cards.forEach((card: any, index: number) => {
            saveCard(card);
        });
    }

    const processSwap = (card: number, slot: number) => {
        // only allow an authorized user to make these changes
        if(event.final || !authValues.isCoordinator){
            return;
        }
        // if selectedPlayer is null, this is the 'from' selection
        if (!selectedPlayer) {
            setSelectedPlayer({'card': card, 'slot': slot})
            return;
        }

        // Save the originally selected player data, then make the swap
        // @ts-ignore
        var temp_player = cards[selectedPlayer.card].players[selectedPlayer.slot];
        // @ts-ignore
        cards[selectedPlayer.card].players[selectedPlayer.slot] = cards[card].players[slot];
        // @ts-ignore
        cards[card].players[slot] = temp_player;
        setSelectedPlayer(null);

        // @ts-ignore
        cards.forEach((card: any) => {
            saveCard(card);
        })
    }

    const player_item = (card: number, slot: number) => {
        // @ts-ignore
        if (!cards.length > 0) {
            return;
        }
        // @ts-ignore
        const player = cards[card].players[slot];
        const is_selected_player = selectedPlayer && selectedPlayer.card == card && selectedPlayer.slot == slot;
        if (is_selected_player) {
            return (
                <IonItem key={card * slot} onClick={() => processSwap(card, slot)}>
                    {player && player.firstName} {player && player.lastName}
                    <IonIcon icon={enterOutline} slot="end"/>
                </IonItem>
            )
        }

        return (
            <IonItem key={player && player.id || Math.random()} onClick={() => processSwap(card, slot)}>
                {player && player.firstName} {player && player.lastName}
            </IonItem>
        )
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/manage/details/${match.params.id}`}/>
                    </IonButtons>
                    <IonTitle size="large">Cards</IonTitle>
                    {
                        !event.final && authValues.isCoordinator &&
                        <IonButtons slot="end">
                            <IonButton onClick={(e) =>
                                present({
                                    event: e.nativeEvent,
                                })
                            } type="button" slot="end">
                                <IonIcon icon={menuOutline}/>
                            </IonButton>
                        </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {
                    !event.final && authValues.isCoordinator &&
                    <CardsHelpModal showModal={showHelpModal} closeModalHandler={closeModalHandler}/>
                }
                <Suspense fallback={'loading event details...'}>
                    <IonToolbar>
                        <IonTitle>{players && players.length} Players</IonTitle>
                        {
                            !event. final && authValues.isCoordinator &&
                            <IonButtons slot="end">
                                <IonButton fill="solid" color="primary" onClick={makeCards}>Shuffle</IonButton>
                            </IonButtons>
                        }
                    </IonToolbar>
                    {
                        !event.final && authValues.isCoordinator &&
                        <IonItem>
                            <IonLabel>Players per Card</IonLabel>
                            <IonSelect interface="popover" value={ppc} onIonChange={(e: any) => setPpc(e.detail.value)}>
                                <IonSelectOption value="3">3</IonSelectOption>
                                <IonSelectOption value="4">4</IonSelectOption>
                                <IonSelectOption value="5">5</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    }
                    {
                        // @ts-ignore
                        cards && cards.map((card: any, index: number) => {
                                return (
                                    <IonCard key={index}>
                                        <IonItem>
                                            <IonTitle onClick={() => processAddPlayerToCard(index)}>{card.name}</IonTitle>
                                            {/*<IonIcon onClick={() => addSlot(index)} icon={addOutline}/>*/}
                                        </IonItem>
                                        <IonCardContent>
                                            {
                                                card && card.players && card.players.map((player: any, i: number) => {
                                                        return (player_item(index, i))
                                                    }
                                                )
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                )
                            }
                        )
                    }
                </Suspense>
            </IonContent>
            {
                !event.final && authValues.isCoordinator &&
                <IonFooter>
                    <IonToolbar>
                        <IonTitle>All Done?</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={handleDoneClick} routerDirection="back"
                                       routerLink={`/manage/details/${match.params.id}`}>Done</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            }
        </IonPage>
    );
};

export default ManageCards;
