import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Scoring.css';
import HeaderContainer from "../components/Header";
import React from "react";

const Scoring: React.FC = () => {
  return (
    <IonPage>
        <HeaderContainer name="Scoring" />
        <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Scoring</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer name="Scoring page" />
      </IonContent>
    </IonPage>
  );
};

export default Scoring;
