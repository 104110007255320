import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent, IonFooter,
    IonHeader,
    IonIcon, IonInput, IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar,
    IonAccordionGroup,
    useIonLoading, useIonPopover, useIonToast, IonAccordion
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {menuOutline, removeCircleOutline, ribbon} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import useEvent from "../hooks/event-provider";
import useCtps from '../hooks/ctp-provider';
import useCards from "../hooks/card-provider";
import AuthContext from "../auth-context";
import usePlayersInfo from "../hooks/player-info-provider";

interface PlayersDetailsPageProps extends RouteComponentProps<{
    id: string;
}> {
}

const PlayerDetails: React.FC<PlayersDetailsPageProps> = ({match, history}) => {
    const {authValues} = useContext(AuthContext);
    const [showMembershipAlert, setShowMembershipAlert] = useState(false);
    // @ts-ignore
    const [{api_players_info}, {getPlayerInformation, addPlayerMembership}]: any = usePlayersInfo(match.params.id);
    const [player, setPlayer] = useState<any>();
    const [presentLoader, dismissLoader] = useIonLoading();
    const [presentToast, dismissToast] = useIonToast();

    useEffect(() => {
        presentLoader({message: "Loading player stats..."});
        // Load the player by ID
        getPlayerInformation(match.params.id).then((player: any) => {
            setPlayer(player);
            dismissLoader();
        })
    }, [])

    const remove_ctp = (ctp: any) => {
        // Remove a membership here
        // deleteCtp(ctp.id);
    }

    const handleAddMembershipAlert = () => {
        setShowMembershipAlert(true);
    }

    const submitHandler = (value: any) => {
        // Add a membership here
        addPlayerMembership({
            'playerId': match.params.id,
            'date': value.date
        }).then(() => {

            // Refresh the player data
            getPlayerInformation(match.params.id).then((player: any) => {
                setPlayer(player);
                // dismissLoader();
            })

            presentToast({
                message: `${new Date(value.date).getFullYear()} membership has been added!`,
                duration: 3000
            })
        }).catch(() => {
            presentToast({
                message: `Oops! Something went wrong adding the new membership.`,
                duration: 2000
            })
        })

        setShowMembershipAlert(false);
    }

    const PopoverList: React.FC<{
        onHide: () => void;
        addMembership: () => void;
    }> = ({onHide, addMembership}) => {

        const handleAddMembership = () => {
            onHide();
            addMembership();
        }

        return (
            <IonList>
                <IonItem button onClick={handleAddMembership}>Add Membership</IonItem>
                <IonItem lines="none" detail={false} button onClick={onHide}>
                    Close
                </IonItem>
            </IonList>
        )
    };

    const [present, dismiss] = useIonPopover(PopoverList, {
        onHide: () => dismiss(),
        addMembership: handleAddMembershipAlert,
    })

    const isNumber = (number: any) => {
        return typeof number === "number"
    }
    const handicapDisplay = (handicap: any) => {
        if (handicap.handicap == undefined || !isNumber(handicap.handicap)) {
            return (<i>est</i>);
        }
        return handicap.handicap;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/players`}/>
                    </IonButtons>
                    <IonTitle size="large">{player && player.firstName} {player && player.lastName}</IonTitle>
                    {
                        authValues.isCoordinator &&
                        <IonButtons slot="end">
                            <IonButton onClick={(e) =>
                                present({
                                    event: e.nativeEvent,
                                })
                            } type="button" slot="end">
                                <IonIcon icon={menuOutline}/>
                            </IonButton>
                        </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding" fullscreen>
                <IonAlert
                    isOpen={showMembershipAlert}
                    onDidDismiss={() => setShowMembershipAlert(false)}
                    header={"Add Membership"}
                    inputs={[
                        {
                            name: 'date',
                            type: 'date',
                            placeholder: 'Date'
                        }
                    ]}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                setShowMembershipAlert(false)
                            }
                        },
                        {
                            text: 'Submit',
                            handler: submitHandler
                        }
                    ]}
                />
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            {player && player.firstName}'s stats
                        </IonCardTitle>
                        <IonCardContent>
                            <p>{player && player.roundCount} total rounds</p>
                            <p>{player && player.isCurrentYearMember ? new Date().getFullYear() + " Club Member" : "Expired membership"}</p>
                            <p>{player && player.aces.length} club aces</p>
                        </IonCardContent>
                    </IonCardHeader>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            Handicap Stats
                        </IonCardTitle>
                        <IonCardContent>
                            <IonAccordionGroup>
                                {
                                    player && player.handicaps.map((handicap: any, index: number) => (
                                        <IonAccordion value={handicap.courseName} key={handicap.courseId}>
                                            <IonItem slot="header">
                                                <IonLabel>
                                                    {handicap.courseName}
                                                </IonLabel>
                                                <IonBadge slot="end">{handicapDisplay(handicap)}</IonBadge>
                                            </IonItem>
                                            <IonCard slot="content" className="md-0">
                                                <IonCardContent>
                                                    <IonList>
                                                        <IonItem>
                                                            <IonLabel>Total Rounds</IonLabel>
                                                            <IonBadge>
                                                                {
                                                                    player && player.courseStats &&
                                                                    player.courseStats.find((x: any) => x.courseId == handicap.courseId).totalRounds
                                                                }
                                                            </IonBadge>
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>Total Doubles Rounds</IonLabel>
                                                            <IonBadge>
                                                                {
                                                                    player && player.courseStats &&
                                                                    player.courseStats.find((x: any) => x.courseId == handicap.courseId).totalRoundsDoubles
                                                                }
                                                            </IonBadge>
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>Current Handicap</IonLabel>
                                                            <IonBadge>{handicapDisplay(handicap)}</IonBadge>
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>Average Score</IonLabel>
                                                            <IonBadge>
                                                                {
                                                                    player && player.courseStats &&
                                                                    player.courseStats.find((x: any) => x.courseId == handicap.courseId).averageScore
                                                                }
                                                            </IonBadge>
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>Hottest Round
                                                            <p>
                                                                {
                                                                    player && player.courseStats &&
                                                                    player.courseStats.find((x: any) => x.courseId == handicap.courseId).hottestRound != null &&
                                                                    new Date(player.courseStats.find((x: any) => x.courseId == handicap.courseId).hottestRound.date).toLocaleDateString()
                                                                }
                                                            </p>
                                                            </IonLabel>
                                                            <IonBadge>
                                                                {
                                                                    player && player.courseStats &&
                                                                    player.courseStats.find((x: any) => x.courseId == handicap.courseId).hottestRound?.score
                                                                }
                                                            </IonBadge>
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>Coldest Round
                                                                <p>
                                                                    {
                                                                        player && player.courseStats &&
                                                                        player.courseStats.find((x: any) => x.courseId == handicap.courseId).coldestRound != null &&
                                                                        new Date(player.courseStats.find((x: any) => x.courseId == handicap.courseId).coldestRound.date).toLocaleDateString()
                                                                    }
                                                                </p>
                                                            </IonLabel>
                                                            <IonBadge>
                                                                {
                                                                    player && player.courseStats &&
                                                                    player.courseStats.find((x: any) => x.courseId == handicap.courseId).coldestRound?.score
                                                                }
                                                            </IonBadge>
                                                        </IonItem>
                                                    </IonList>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonAccordion>
                                    ))
                                }
                            </IonAccordionGroup>
                        </IonCardContent>
                    </IonCardHeader>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            Membership & Annual Stats
                        </IonCardTitle>
                        <IonCardContent>
                            <IonAccordionGroup>
                                {
                                    player && player.memberships.map((membership: any, index: number) => (
                                        <IonAccordion key={index} value={membership.date}>
                                            <IonItem slot="header">
                                                <IonLabel>
                                                    {new Date(membership.date).getFullYear()}
                                                </IonLabel>
                                            </IonItem>
                                            <IonCard slot="content" className="md-0">
                                                <IonCardContent>
                                                    <IonList>
                                                        <IonItem>
                                                            <IonLabel>
                                                                Total Rounds
                                                                {/*<p>18 Singles, 25 Doubles</p>*/}
                                                            </IonLabel>
                                                            {/*<IonBadge>38</IonBadge>*/}
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>Most Played Course
                                                                {/*<p>*/}
                                                                {/*    Doris Leeper B*/}
                                                                {/*</p>*/}
                                                            </IonLabel>
                                                            {/*<IonBadge>65</IonBadge>*/}
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>
                                                                Average Score
                                                                <p>All courses</p>
                                                            </IonLabel>
                                                            {/*<IonBadge>-4</IonBadge>*/}
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>
                                                                Hottest Round
                                                                {/*<p>Glencoe B</p>*/}
                                                            </IonLabel>
                                                            {/*<IonBadge>45</IonBadge>*/}
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>
                                                                Coldest Round (date)
                                                                {/*<p>Crossroads</p>*/}
                                                            </IonLabel>
                                                            {/*<IonBadge>82</IonBadge>*/}
                                                        </IonItem>
                                                    </IonList>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonAccordion>
                                    ))
                                }
                            </IonAccordionGroup>

                        </IonCardContent>
                    </IonCardHeader>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default PlayerDetails;
