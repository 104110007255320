import React, {useCallback, useContext} from 'react';
import useFetch from "./fetch";
import axios from "axios";
import firebase from "firebase";
import AuthContext from "../auth-context";

function usePlayersInfo() {
    const url = `api/players`;
    const {status, data} = useFetch(url, false);
    let api_players_info = data;

    // handlers
    async function addPlayer(playerData: any) {
        const userToken = await firebase.auth().currentUser?.getIdToken();
        const obj = {
            'firstName': playerData.firstName,
            'lastName': playerData.lastName
        }
        const requestOptions = {
            headers: {'Content-Type': 'application/json', 'Authorization': userToken}
        };
        const result = await axios.post(`api/players`, obj, requestOptions)
        return result.data;
    }
    
    async function addMembership(playerData: any) {
        const userToken = await firebase.auth().currentUser?.getIdToken();
        const obj = {
            'playerId': playerData.playerId,
            'date': playerData.date
        }
        const requestOptions = {
            headers: {'Content-Type': 'application/json', 'Authorization': userToken}
        };
        const result = await axios.post(`api/players/addmembership`, obj, requestOptions)
        return result.data;
    }
    
    async function getPlayerInformation(playerId: string){
        const userToken = await firebase.auth().currentUser?.getIdToken();
        // const obj = {
        //     'firstName': playerData.firstName,
        //     'lastName': playerData.lastName
        // }
        const requestOptions = {
            headers: {'Content-Type': 'application/json', 'Authorization': userToken}
        };
        const result = await axios.get(`api/players/information/${playerId}`, requestOptions)
        return result.data;
    }

    const handlers = {
        addNewPlayer: useCallback(addPlayer, []),
        addPlayerMembership: useCallback(addMembership, []),
        getPlayerInformation: useCallback(getPlayerInformation, [])
    }

    return [{api_players_info}, handlers];
}

export default usePlayersInfo