import React, {useCallback} from 'react';
import useFetch from "./fetch";
import axios from "axios";
import firebase from "firebase";


function useStats() {
    const url = `/api/stats`;
    const {status, data} = useFetch(url, false);
    const api_stats = data;

    const handlers = {
        // addNewPlayer: useCallback(addPlayer, [])
    }

    return [{api_stats}, handlers];
}

export default useStats