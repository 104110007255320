import {Redirect, Route} from 'react-router-dom';
import {
    IonApp, IonContent,
    IonIcon,
    IonLabel,
    IonLoading,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs, setupIonicReact,
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {ellipse, square, triangle} from 'ionicons/icons';
import Events from './pages/Events';
import Scoring from './pages/Scoring';
import Manage from './pages/Manage';
import Splash from './pages/Splash';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import AuthContext from './auth-context';

/* Theme variables */
import './theme/variables.css';
import Login from "./pages/Login";
import React, {Suspense, useEffect} from "react";
import ManageDetails from "./pages/ManageDetails";
import ManagePlayers from "./pages/ManagePlayers";
import ManageCards from "./pages/ManageCards";
import ManageNotes from "./pages/ManageNotes";
import ErrorBoundary from "./components/errorboundary";
import ManageCtps from "./pages/ManageCtp";
import ManageHoleAssignments from "./pages/ManageHoleAssignments";
import ManageScores from "./pages/ManageScores";
import ManagePayouts from "./pages/ManagePayout";
import ManageCardsDoubles from "./pages/ManageCardsDoubles";
import Players from "./pages/Players";
import PlayerDetails from "./pages/PlayerDetails";
import More from "./pages/More";

setupIonicReact({mode: 'md'});

const App: React.FC = () => {

    const {authValues, initialize} = React.useContext(AuthContext);

    // loading screen
    const [showLoading, setShowLoading] = React.useState(true);

    useEffect(() => {
        // do initialize
        (async () => {
            await initialize();
            setShowLoading(false);
        })();

    }, [])

    if (showLoading) {
        return (
            <IonApp>
                <IonLoading message="Initializing App" isOpen={showLoading}/>
            </IonApp>
        );
    }

    return (
        <IonApp>
            {!authValues.authenticated ? (
                <IonReactRouter>
                    <Route path="/login" component={Login} exact/>
                    <Route path="*" render={() => <Redirect to="/login"/>} exact/>
                </IonReactRouter>
            ) : (
                <IonReactRouter>
                    <Route path="/login" render={() => <Redirect to="/"/>} exact/>
                    <IonTabs>
                        <IonRouterOutlet>
                            <Route exact path="/players">
                                <Players/>
                            </Route>
                            <Route exact path="/scoring">
                                <Scoring/>
                            </Route>
                            <Route exact path="/manage">
                                <Manage/>
                            </Route>
                            <Route exact path="/more">
                                <More/>
                            </Route>
                            <Route exact path="/">
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <Splash/>
                                    </ErrorBoundary>
                                </Suspense>
                            </Route>
                            <Route exact path="/login">
                                <Login/>
                            </Route>
                            <Route exact path="/manage/details/:id" render={props =>
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <ManageDetails {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            }/>
                            <Route exact path="/manage/players/:id" render={props =>
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <ManagePlayers {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            }/>
                            <Route exact path="/manage/cards/:id" render={props =>
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <ManageCards {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            }/>
                            <Route exact path="/manage/doublescards/:id" render={props =>
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <ManageCardsDoubles {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            }/>
                            <Route exact path="/manage/ctps/:id" render={props =>
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <ManageCtps {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            }/>
                            <Route exact path="/manage/holeassignments/:id" render={props =>
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <ManageHoleAssignments {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            }/>
                            {/*<Route exact path="/manage/notes/:id" render={props =>*/}
                            {/*    <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>*/}
                            {/*        <ErrorBoundary>*/}
                            {/*            <ManageNotes {...props} />*/}
                            {/*        </ErrorBoundary>*/}
                            {/*    </Suspense>*/}
                            {/*}/>*/}
                            <Route exact path="/manage/scores/:id" render={props =>
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <ManageScores {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            }/>
                            <Route exact path="/manage/payouts/:id" render={props =>
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <ManagePayouts {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            }/>
                            <Route exact path="/player/:id" render={props =>
                                <Suspense fallback={<IonLoading message="Loading..." isOpen={true}/>}>
                                    <ErrorBoundary>
                                        <PlayerDetails {...props} />
                                    </ErrorBoundary>
                                </Suspense>
                            }/>
                        </IonRouterOutlet>
                        <IonTabBar slot="bottom">
                            <IonTabButton tab="players" href="/players">
                                <IonIcon icon={triangle}/>
                                <IonLabel>Players</IonLabel>
                            </IonTabButton>
                            {/*<IonTabButton tab="scoring" href="/scoring">*/}
                            {/*    <IonIcon icon={ellipse}/>*/}
                            {/*    <IonLabel>Scoring</IonLabel>*/}
                            {/*</IonTabButton>*/}
                            <IonTabButton tab="manage" href="/manage">
                                <IonIcon icon={square}/>
                                <IonLabel>Events</IonLabel>
                            </IonTabButton>
                            {/*<IonTabButton tab="more" href="/more">*/}
                            {/*    <IonIcon icon={ellipse}/>*/}
                            {/*    <IonLabel>More</IonLabel>*/}
                            {/*</IonTabButton>*/}
                        </IonTabBar>
                    </IonTabs>
                </IonReactRouter>
            )}

        </IonApp>
    )
}

export default App;
