import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonModal,
    IonButton,
    IonButtons, IonBackButton, IonIcon, IonBadge, IonLabel, IonSearchbar, useIonLoading, useIonAlert, IonAlert
} from '@ionic/react';
import React, {Suspense, useContext, useEffect, useRef, useState} from "react";
import useEvents from "../hooks/events-provider";
import EventType from "../types/event";
import HeaderContainer from "../components/Header";
import {person, personAddOutline, ribbon} from "ionicons/icons";
import usePlayersInfo from "../hooks/player-info-provider";
import AuthContext from "../auth-context";

const More: React.FC = () => {
    const {authValues} = useContext(AuthContext);
    const [searchText, setSearchText] = useState('');
    const [presentLoader, dismiss] = useIonLoading();
    const searchRef = useRef<HTMLIonSearchbarElement>();
    const [presentAlert, dismissAlert] = useIonAlert();


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/"}/>
                    </IonButtons>
                    <IonTitle size="large">More</IonTitle>
                    {
                        authValues.isCoordinator &&
                        <IonButtons slot="end">
                            <IonButton>
                                <IonIcon icon={personAddOutline}/>
                            </IonButton>
                        </IonButtons>
                    }
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar inputMode="text" value={searchText}
                                  onIonChange={e => setSearchText(e.detail.value!)}/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <Suspense fallback={'loading events...'}>
                    <IonList>
                        <IonItem>
                            <IonLabel>Site Settings</IonLabel>
                            <IonIcon style={{color: "gold"}} slot="end" icon={ribbon}/>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Contributors</IonLabel>
                            <IonIcon style={{color: "gold"}} slot="end" icon={ribbon}/>
                        </IonItem>
                    </IonList>
                </Suspense>
            </IonContent>
        </IonPage>
    );
};

export default More;
