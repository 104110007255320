import React, {useCallback, useContext, useState} from 'react';
import useFetch from "./fetch";
import axios from "axios";
import firebase from "firebase";
import AuthContext from "../auth-context";
import {set} from "react-hook-form";

function usePlayers(course_id: string) {
    const [api_players, setApi_players] = useState<any[]>([]);
    // const url = `api/players/handicapsbycourse/${course_id}`;
    // const {status, data} = useFetch(url, true);
    // let api_players = data;

    // handlers
    async function addPlayer(playerData: any) {
        const userToken = await firebase.auth().currentUser?.getIdToken();
        const obj = {
            'firstName': playerData.firstName,
            'lastName': playerData.lastName
        }
        const requestOptions = {
            headers: {'Content-Type': 'application/json', 'Authorization': userToken}
        };
        const result = await axios.post(`api/players`, obj, requestOptions)
        setApi_players(p => [...api_players, result.data]);
        return result.data;
    }
    
    async function getPlayerHandicapsByCourse(courseId: string){
        const userToken = await firebase.auth().currentUser?.getIdToken();
        // const obj = {
        //     'firstName': playerData.firstName,
        //     'lastName': playerData.lastName
        // }
        const requestOptions = {
            headers: {'Content-Type': 'application/json', 'Authorization': userToken}
        };
        const result = await axios.get(`api/players/handicapsbycourse/${courseId}`, requestOptions)
        setApi_players(result.data);
        return result.data;
    }

    const handlers = {
        addNewPlayer: useCallback(addPlayer, []),
        getCourseHandicapsByCourse: useCallback(getPlayerHandicapsByCourse, [])
    }

    return [{api_players}, handlers];
}

export default usePlayers