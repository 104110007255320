import React, { useCallback } from 'react';
import { useFirestoreCollectionData, useFirestore } from 'reactfire';
import IEvent from "../types/event";
import useTenantProvider from "./tenant-provider";

interface Event {
    id: string,
    name: string,
    final: boolean,
    course_id: string,
    date: string,
    players: any
}

function useEvents()  {
    const tenant = useTenantProvider();
    // lazy load the Firestore SDK
    const ref = useFirestore().collection(`tenants/${tenant}/event`);
    
    // subscribe to the collection
    const { data: events } = useFirestoreCollectionData(ref.orderBy('date', 'desc'), {idField: 'id'});
    
    // create handlers
    async function addEvent(newEventData: IEvent){
        await ref.add(newEventData);
    }
    async function removeEvent(eventId: string){
        await ref.doc(eventId).delete();
    }
    async function saveEvent(event: IEvent){
        await ref.doc(event.id).set(event, { merge: true });
    }
    async function addPlayer(event_id: any, playerData: any){
        await ref.doc(event_id).collection('players').add(playerData);
    }
    
    const handlers = {
        addEvent: useCallback(addEvent, [ref]),
        removeEvent: useCallback(removeEvent, [ref]),
        saveEvent: useCallback(saveEvent, [ref]),
        addPlayer: useCallback(addPlayer, [ref]),
    }
    
    // return array of [data, handlers] to match hooks like useState
    return [events, handlers];
}

export default useEvents;