import {
    IonAlert,
    IonAvatar,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent, IonFooter,
    IonGrid,
    IonHeader,
    IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions,
    IonItemSliding, IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonModal,
    IonNote,
    IonPage, IonReorder, IonReorderGroup, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonText,
    IonTitle,
    IonToolbar, useIonAlert, useIonPopover
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import {useFirestoreDocData, useFirestore, SuspenseWithPerf} from "reactfire";
import React, {Fragment, Suspense, useContext, useEffect, useRef, useState} from "react";
import AuthContext from '../auth-context'
import {
    reloadOutline,
    addOutline,
    enterOutline,
    informationCircleOutline,
    menuOutline,
    checkmarkCircleOutline,
    home,
    removeCircleOutline, card
} from "ionicons/icons";
import HeaderContainer from "../components/Header";
import {RouteComponentProps} from "react-router";
import useEvents from "../hooks/events-provider";
import useEvent from "../hooks/event-provider";
import useCards from "../hooks/card-provider";
import {ItemReorderEventDetail} from "@ionic/core";
import usePlayers from "../hooks/player-provider";
import firebase from 'firebase';

interface ManageHoleAssignmentsPageProps extends RouteComponentProps<{
    id: string;
}> {
}

function HoleAssignmentsHelpModal({closeModalHandler, showModal}: any) {
    const closeHandler = () => {
        closeModalHandler();
    }

    return <IonModal isOpen={showModal}>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Help With Hole Assignments</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={closeHandler}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className="ion-padding" fullscreen>
                <IonText>
                    <IonNote>TODO</IonNote>
                    {/*<h4>Initial setup</h4>*/}
                    {/*<ol>*/}
                    {/*    <li>Select the desired 'Players Per Card'. This defaults to 4.</li>*/}
                    {/*    <li>Click 'Shuffle' - This will randomly assign players into groups.</li>*/}
                    {/*</ol>*/}
                    {/*<h4>Changing Groups</h4>*/}
                    {/*<p>You can move players between groups by clicking a player, then clicking where you want to move*/}
                    {/*    them to.</p>*/}
                </IonText>
            </IonContent>
        </IonHeader>
    </IonModal>
}

const ManageHoleAssignments: React.FC<ManageHoleAssignmentsPageProps> = ({match, history}) => {
    const {authValues} = useContext(AuthContext);
    const [{event, players}, {saveField}]: any = useEvent(match.params.id);
    // @ts-ignore
    const [cards, {saveCard}] = useCards(match.params.id);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        // Detect and set processing state
        //@ts-ignore
        if (!event.final && authValues.isCoordinator) {
            saveField({"manageHoleAssignmentsProcessed": "processing"});
        }
    }, [])
    
    const handleDoneClick = () => {
        saveField({"manageHoleAssignmentsProcessed": true});
    }
   
    const closeModalHandler = () => {
        setShowHelpModal(false);
    }

    const showHelp = () => {
        setShowHelpModal(true);
    }

    const openAlert = (card:any) => {
        if (event.final || !authValues.isCoordinator){
            return;
        }
        
        setSelectedCard(card);
        // setShowHoleAssignmentModal(true);
        setShowAlert(true);
    }
    const holeAssignmentSubmitHandler = (value: any) => {
        // @ts-ignore
        selectedCard.holeAssignment = value.hole;
        saveCard(selectedCard);
        setShowAlert(false);
    }

    const doubles_display = (card: any) => {
        return card && card.players && card.players.map((group: any, i: number) => {
                return (
                    // <IonItem key={player.playerId}>{player && player.firstName} {player && player.lastName}</IonItem>
                    <IonItem key={group && group.id || Math.random()}>
                        {group && group.playerOne.firstName} {group && group.playerOne.lastName} {group && group.playerTwo && "/"} {group && group.playerTwo && group.playerTwo.firstName} {group && group.playerTwo && group.playerTwo.lastName} {group && !group.playerTwo && "- ODD MAN"}
                    </IonItem>
                )
            }
        )
    }
    
    const handicaps_display = (card: any) => {
        return card && card.players && card.players.map((player: any, i: number) => {
                return (
                    <IonItem key={player.playerId}>{player && player.firstName} {player && player.lastName}</IonItem>
                )
            }
        )
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/manage/details/${match.params.id}`}/>
                    </IonButtons>
                    <IonTitle size="large">Hole Assignments</IonTitle>
                    {
                        !event.final && authValues.isCoordinator &&
                        <IonButtons slot="end">
                            <IonButton onClick={(e) => setShowHelpModal(true)} type="button" slot="end">
                                <IonIcon icon={informationCircleOutline}/>
                            </IonButton>
                        </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonAlert 
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={"Assign Starting Hole"}
                    inputs={[
                        {
                            attributes: {
                                autofocus: 'true'
                            },
                            name: 'hole',
                            type: 'number',
                            min: '1',
                            placeholder: 'Starting Hole'
                        }
                    ]}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                setShowAlert(false)
                            }
                        },
                        {
                            text: 'Submit',
                            handler: holeAssignmentSubmitHandler 
                        }
                    ]}
                />
                {
                    !event.final && authValues.isCoordinator &&
                    <IonTitle className="ion-padding">Click a card to assign a starting hole</IonTitle>
                }
                {
                    !event.final && authValues.isCoordinator &&
                    <HoleAssignmentsHelpModal showModal={showHelpModal} closeModalHandler={closeModalHandler}/>
                }
                <Suspense fallback={'loading hole assignment details...'}>
                    {
                        // @ts-ignore
                        cards && cards.map((card: any, index: number) => {
                                return (
                                    <IonCard onClick={() => openAlert(card)} key={index}>
                                        <IonItem key={card.id}>
                                            <IonTitle>{card.name}</IonTitle>
                                            {card.holeAssignment && <IonBadge slot="end">Hole {card.holeAssignment}</IonBadge>}
                                            {/*<IonIcon onClick={() => addSlot(index)} icon={addOutline}/>*/}
                                        </IonItem>
                                        <IonCardContent>
                                            {
                                                event.type === "Doubles" ? doubles_display(card) : handicaps_display(card)
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                )
                            }
                        )
                    }
                </Suspense>
            </IonContent>
            {
                !event.final && authValues.isCoordinator &&
                <IonFooter>
                    <IonToolbar>
                        <IonTitle>All Done?</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={handleDoneClick} routerDirection="back"
                                       routerLink={`/manage/details/${match.params.id}`}>Done</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            }
        </IonPage>
    );
};

export default ManageHoleAssignments;
