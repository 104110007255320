import React, {useContext, useEffect, useReducer, useRef, useState} from 'react';
import firebase from "firebase";
import AuthContext from "../auth-context";

const useFetch = (url: string, isProtectedRoute: boolean) => {
    const cache = useRef<any>({});
    const {authValues} = useContext(AuthContext);
    
    const initialState = {
        status: 'idle',
        error: null,
        data: [],
    };
    
    const [state, dispatch] = useReducer((state: any, action: any) => {
        switch (action.type) {
            case 'FETCHING':
                return { ...initialState, status: 'fetching' };
            case 'FETCHED':
                return { ...initialState, status: 'fetched', data: action.payload };
            case 'FETCH_ERROR':
                return { ...initialState, status: 'error', error: action.payload };
            default:
                return state;
        }
    }, initialState);
    
    useEffect(() => {
        let cancelRequest = false;
        if (!url) return;
        
        const fetchData = async (token: any) => {
            dispatch({ type: 'FETCHING' });
            // if (cache.current[url]) {
            //     const data = cache.current[url];
            //     dispatch({ type: 'FETCHED', payload: data });
            // } else {
                try {

                    if (isProtectedRoute && !authValues.isCoordinator){
                        dispatch({ type: 'FETCH_ERROR', payload: 'not authorized'});
                        return;
                    }

                    const response = await fetch(url, {
                        headers: new Headers({
                            'Authorization': token
                        })
                    });
                    const data = await response.json();
                    if (await response.status != 200){
                        dispatch({ type: 'FETCH_ERROR', payload: data.message});
                        return;
                    }
                    cache.current[url] = data;
                    if (cancelRequest) return;
                    dispatch({ type: 'FETCHED', payload: data });
                } catch (error: any) {
                    if (cancelRequest) return;
                    dispatch({ type: 'FETCH_ERROR', payload: error.message });
                }
            // }
        };

        const userToken = firebase.auth().currentUser?.getIdToken().then((token: any) => {
            fetchData(token);
        });

        return function cleanup() {
            cancelRequest = true;
        };
    }, [url]);

    return state;
};

export default useFetch;