import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonModal,
    IonButton,
    IonButtons, IonBackButton, IonIcon, IonBadge, IonLabel, IonSearchbar, useIonLoading, useIonAlert, IonAlert
} from '@ionic/react';
import React, {Suspense, useContext, useEffect, useRef, useState} from "react";
import useEvents from "../hooks/events-provider";
import EventType from "../types/event";
import HeaderContainer from "../components/Header";
import {person, personAddOutline, ribbon} from "ionicons/icons";
import usePlayersInfo from "../hooks/player-info-provider";
import AuthContext from "../auth-context";

const Players: React.FC = () => {
    const {authValues} = useContext(AuthContext);
    const [{api_players_info}, {addNewPlayer}]: any = usePlayersInfo();
    const [searchText, setSearchText] = useState('');
    const [presentLoader, dismiss] = useIonLoading();
    const [showNewPlayerAlert, setShowNewPlayerAlert] = useState(false);
    const searchRef = useRef<HTMLIonSearchbarElement>();
    const [players, setPlayers] = useState<any[]>([]);
    const [presentAlert, dismissAlert] = useIonAlert();

    useEffect(() => {
        if (!api_players_info){
            presentLoader({message: 'Loading players...'});
        }
        searchRef.current?.focus();
    }, [])
    
    useEffect(() => {
        if (api_players_info.length > 0){
            setPlayers(api_players_info);
            dismiss();
        }
    }, [api_players_info])
    
    const addNewPlayerHandler = (item: any) => {
        const found_player = api_players_info.find((x: any) => x.firstName == item.firstName && x.lastName == item.lastName);
        if (!found_player) {
            const result = addNewPlayer(item).then((res: any) => {
                // add to state object
                setPlayers(players => [...players, res]);
            });
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/"}/>
                    </IonButtons>
                    <IonTitle size="large">Players</IonTitle>
                    {
                        authValues.isCoordinator &&
                            <IonButtons slot="end">
                                <IonButton onClick={() => setShowNewPlayerAlert(true)}>
                                    <IonIcon icon={personAddOutline}/>
                                </IonButton>
                            </IonButtons>
                    }
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar inputMode="text" value={searchText}
                                  onIonChange={e => setSearchText(e.detail.value!)}/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <Suspense fallback={'loading events...'}>
                    <IonAlert
                        isOpen={showNewPlayerAlert}
                        onDidDismiss={() => setShowNewPlayerAlert(false)}
                        cssClass='my-custom-class'
                        header={'Create a new player'}
                        inputs={[
                            {
                                name: 'firstName',
                                type: 'text',
                                placeholder: 'First Name'
                            },
                            {
                                name: 'lastName',
                                type: 'text',
                                placeholder: 'Last Name'
                            }
                        ]}
                        buttons={[
                            {
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: () => {
                                    setShowNewPlayerAlert(false);
                                }
                            },
                            {
                                text: 'Ok',
                                handler: addNewPlayerHandler
                            }
                        ]}
                    />
                    <IonList>
                        {
                            players && players
                                .filter((x: any) => x.firstName && x.firstName.toLowerCase().includes(searchText.toLowerCase()) || x.lastName && x.lastName.toLowerCase().includes(searchText.toLocaleLowerCase())).map((item: any, index: number) => {
                                        return (
                                            <IonItem routerLink={`/player/${item.playerId}`} key={item.playerId}>
                                                <IonLabel>{item.firstName} {item.lastName}</IonLabel>
                                                {
                                                    item.isCurrentYearMember &&
                                                    <IonIcon style={{color: "gold"}} slot="end" icon={ribbon}/>
                                                }
                                            </IonItem>
                                        )
                                    }
                                )}
                    </IonList>
                </Suspense>
            </IonContent>
        </IonPage>
    );
};

export default Players;
