import React, {useCallback} from 'react';
import {useFirestoreCollectionData, useFirestore, useFirestoreDocData} from 'reactfire';
import IEvent from "../types/event";
import useEvent from "./event-provider";

const handicaps: any[] = [
    {
        'players': 3,
        'payouts': [
            {
                'place': 1,
                'amount': 15
            }
        ]
    },
    {
        'players': 4,
        'payouts': [
            {
                'place': 1,
                'amount': 20 
            }
        ]
    },
    {
        'players': 5,
        'payouts': [
            {
                'place': 1,
                'amount': 15
            },
            {
                'place': 2,
                'amount': 10
            }
        ]
    },
    {
        'players': 6,
        'payouts': [
            {
                'place': 1,
                'amount': 20
            },
            {
                'place': 2,
                'amount': 10
            }
        ]
    },
    {
        'players': 7,
        'payouts': [
            {
                'place': 1,
                'amount': 25
            },
            {
                'place': 2,
                'amount': 10
            }
        ]
    },
    {
        'players': 8,
        'payouts': [
            {
                'place': 1,
                'amount': 25
            },
            {
                'place': 2,
                'amount': 10
            },
            {
                'place': 3,
                'amount': 5
            }
        ]
    },
    {
        'players': 9,
        'payouts': [
            {
                'place': 1,
                'amount': 25
            },
            {
                'place': 2,
                'amount': 15
            },
            {
                'place': 3,
                'amount': 5
            }
        ]
    },
    {
        'players': 10,
        'payouts': [
            {
                'place': 1,
                'amount': 25
            },
            {
                'place': 2,
                'amount': 15
            },
            {
                'place': 3,
                'amount': 10
            }
        ]
    },
    {
        'players': 11,
        'payouts': [
            {
                'place': 1,
                'amount': 25
            },
            {
                'place': 2,
                'amount': 15
            },
            {
                'place': 3,
                'amount': 10
            },
            {
                'place': 4,
                'amount': 5
            }
        ]
    },
    {
        'players': 12,
        'payouts': [
            {
                'place': 1,
                'amount': 30
            },
            {
                'place': 2,
                'amount': 15
            },
            {
                'place': 3,
                'amount': 10
            },
            {
                'place': 4,
                'amount': 5
            }
        ]
    },
    {
        'players': 13,
        'payouts': [
            {
                'place': 1,
                'amount': 30
            },
            {
                'place': 2,
                'amount': 20
            },
            {
                'place': 3,
                'amount': 10
            },
            {
                'place': 4,
                'amount': 5
            }
        ]
    },
    {
        'players': 14,
        'payouts': [
            {
                'place': 1,
                'amount': 35
            },
            {
                'place': 2,
                'amount': 20
            },
            {
                'place': 3,
                'amount': 10
            },
            {
                'place': 4,
                'amount': 5
            }
        ]
    },
    {
        'players': 15,
        'payouts': [
            {
                'place': 1,
                'amount': 35
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 10
            },
            {
                'place': 4,
                'amount': 5
            }
        ]
    },
    {
        'players': 16,
        'payouts': [
            {
                'place': 1,
                'amount': 35
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 15
            },
            {
                'place': 4,
                'amount': 5
            }
        ]
    },
    {
        'players': 17,
        'payouts': [
            {
                'place': 1,
                'amount': 35
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 15
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 18,
        'payouts': [
            {
                'place': 1,
                'amount': 40
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 15
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 19,
        'payouts': [
            {
                'place': 1,
                'amount': 40
            },
            {
                'place': 2,
                'amount': 30
            },
            {
                'place': 3,
                'amount': 15
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 20,
        'payouts': [
            {
                'place': 1,
                'amount': 40
            },
            {
                'place': 2,
                'amount': 30
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 21,
        'payouts': [
            {
                'place': 1,
                'amount': 45
            },
            {
                'place': 2,
                'amount': 30
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 22,
        'payouts': [
            {
                'place': 1,
                'amount': 45
            },
            {
                'place': 2,
                'amount': 30
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 23,
        'payouts': [
            {
                'place': 1,
                'amount': 50
            },
            {
                'place': 2,
                'amount': 30
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 24,
        'payouts': [
            {
                'place': 1,
                'amount': 50
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 25,
        'payouts': [
            {
                'place': 1,
                'amount': 55
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 26,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 27,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 28,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 29,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 30,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 31,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 32,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 33,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 34,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 35,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 36,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 37,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 38,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 39,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 40,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 41,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 42,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 43,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 44,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 45,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 46,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 47,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 48,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 49,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    },
    {
        'players': 50,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            },
            {
                'place': 5,
                'amount': 5
            }
        ]
    }
]

const doubles: any[] = [
    {
        'players': 8,
        'payouts': [
            {
                'place': 1,
                'amount': 30
            },
            {
                'place': 2,
                'amount': 10
            }
        ]
    },
    {
        'players': 9,
        'payouts': [
            {
                'place': 1,
                'amount': 30
            },
            {
                'place': 2,
                'amount': 15
            }
        ]
    },
    {
        'players': 10,
        'payouts': [
            {
                'place': 1,
                'amount': 25
            },
            {
                'place': 2,
                'amount': 15
            },
            {
                'place': 3,
                'amount': 10
            }
        ]
    },
    {
        'players': 11,
        'payouts': [
            {
                'place': 1,
                'amount': 30
            },
            {
                'place': 2,
                'amount': 15
            },
            {
                'place': 3,
                'amount': 10
            }
        ]
    },
    {
        'players': 12,
        'payouts': [
            {
                'place': 1,
                'amount': 30
            },
            {
                'place': 2,
                'amount': 20
            },
            {
                'place': 3,
                'amount': 10
            }
        ]
    },
    {
        'players': 13,
        'payouts': [
            {
                'place': 1,
                'amount': 30
            },
            {
                'place': 2,
                'amount': 20
            },
            {
                'place': 3,
                'amount': 15
            }
        ]
    },
    {
        'players': 14,
        'payouts': [
            {
                'place': 1,
                'amount': 35
            },
            {
                'place': 2,
                'amount': 20
            },
            {
                'place': 3,
                'amount': 15
            }
        ]
    },
    {
        'players': 15,
        'payouts': [
            {
                'place': 1,
                'amount': 35
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 15
            }
        ]
    },
    {
        'players': 16,
        'payouts': [
            {
                'place': 1,
                'amount': 35
            },
            {
                'place': 2,
                'amount': 20
            },
            {
                'place': 3,
                'amount': 15
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 17,
        'payouts': [
            {
                'place': 1,
                'amount': 40
            },
            {
                'place': 2,
                'amount': 20
            },
            {
                'place': 3,
                'amount': 15
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 18,
        'payouts': [
            {
                'place': 1,
                'amount': 40
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 15
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 19,
        'payouts': [
            {
                'place': 1,
                'amount': 40
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 20,
        'payouts': [
            {
                'place': 1,
                'amount': 45
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 21,
        'payouts': [
            {
                'place': 1,
                'amount': 45
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 15
            }
        ]
    },
    {
        'players': 22,
        'payouts': [
            {
                'place': 1,
                'amount': 50
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 15
            }
        ]
    },
    {
        'players': 23,
        'payouts': [
            {
                'place': 1,
                'amount': 55
            },
            {
                'place': 2,
                'amount': 25
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 15
            }
        ]
    },
    {
        'players': 24,
        'payouts': [
            {
                'place': 1,
                'amount': 55
            },
            {
                'place': 2,
                'amount': 30
            },
            {
                'place': 3,
                'amount': 20
            },
            {
                'place': 4,
                'amount': 15
            }
        ]
    },
    {
        'players': 25,
        'payouts': [
            {
                'place': 1,
                'amount': 55
            },
            {
                'place': 2,
                'amount': 30
            },
            {
                'place': 3,
                'amount': 25
            },
            {
                'place': 4,
                'amount': 15
            }
        ]
    },
    {
        'players': 26,
        'payouts': [
            {
                'place': 1,
                'amount': 55
            },
            {
                'place': 2,
                'amount': 30
            },
            {
                'place': 3,
                'amount': 25
            },
            {
                'place': 4,
                'amount': 20
            }
        ]
    },
    {
        'players': 27,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 30
            },
            {
                'place': 3,
                'amount': 25
            },
            {
                'place': 4,
                'amount': 10
            }
        ]
    },
    {
        'players': 28,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 25
            },
            {
                'place': 4,
                'amount': 20
            }
        ]
    },
    {
        'players': 29,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 30
            },
            {
                'place': 4,
                'amount': 20
            }
        ]
    },
    {
        'players': 30,
        'payouts': [
            {
                'place': 1,
                'amount': 60
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 30
            },
            {
                'place': 4,
                'amount': 25
            }
        ]
    },
    {
        'players': 31,
        'payouts': [
            {
                'place': 1,
                'amount': 65
            },
            {
                'place': 2,
                'amount': 35
            },
            {
                'place': 3,
                'amount': 30
            },
            {
                'place': 4,
                'amount': 25
            }
        ]
    }
]

function usePayouts() {

    const handlers = {
        // getCardsDetails: useCallback(getCardDetails, [ref]),
    }

    // return array of [data, handlers] to match hooks like useState
    return [{handicaps, doubles}, handlers];
}

export default usePayouts;