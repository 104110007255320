import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent, IonFooter,
    IonHeader,
    IonIcon, IonInput, IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {removeCircleOutline} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import useEvent from "../hooks/event-provider";
import useCtps from '../hooks/ctp-provider';
import useCards from "../hooks/card-provider";
import AuthContext from "../auth-context";

interface ManageCtpPageProps extends RouteComponentProps<{
    id: string;
}> {
}

function CtpModal ({closeModalHandler, showModal, eventId}: any) {
    // @ts-ignore
    const [event, {addPlayer}] = useEvent(eventId);
    // @ts-ignore
    const [ctp, setCtp] = useState<int>(null);
    // @ts-ignore
    const [ctps, {addCtp, saveCtp}] = useCtps(eventId);

    const closeHandler = () => {
        setCtp(null)
        closeModalHandler();
    }
    
    const submitHandler = () => {
        // save CTP and close
        addCtp({
            hole: ctp
        });
        closeHandler();
    }
    return <IonModal isOpen={showModal}>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Enter Ctps</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={closeHandler}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className="ion-padding" fullscreen>
                <IonInput placeholder="Hole #" type="number" value={ctp} onIonChange={(e) => setCtp(e.detail.value!)} />
                <IonButton expand="full" onClick={submitHandler}>Submit</IonButton>
            </IonContent>
        </IonHeader>
    </IonModal>
}

const ManageCtps: React.FC<ManageCtpPageProps> = ({match, history}) => {
    const {authValues} = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [ctp, setCtp] = useState(null);
    const [{event}, {saveField}]: any = useEvent(match.params.id);
    // @ts-ignore
    const [ctps, {addCtp, deleteCtp}] = useCtps(match.params.id);

    useEffect(() => {
        // Detect and set processing state
        //@ts-ignore
        if (!event.final && authValues.isCoordinator) {
            saveField({"manageCtpsProcessed": "processing"});
        }
    }, [])
    const handleDoneClick = () => {
        saveField({"manageCtpsProcessed": true});
    }
    
    const remove_ctp = (ctp: any) => {
        deleteCtp(ctp.id);
    }
    
    const submitHandler = (value: any) => {
        // @ts-ignore
        addCtp({
            hole: value.hole
        });
        setShowAlert(false);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/manage/details/${match.params.id}`}/>
                    </IonButtons>
                    <IonTitle size="large">CTPs</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding" fullscreen>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={"Assign CTP Hole"}
                    inputs={[
                        {
                            name: 'hole',
                            type: 'number',
                            min: '1',
                            placeholder: 'Hole'
                        }
                    ]}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                setShowAlert(false)
                            }
                        },
                        {
                            text: 'Submit',
                            handler: submitHandler
                        }
                    ]}
                />
                {
                    !event.final && authValues.isCoordinator &&
                    <IonButton type="button" expand="full" onClick={() => setShowAlert(true)}>Add
                        CTP</IonButton>
                }
                <IonList>{
                    // @ts-ignore
                    ctps && ctps.map((ctp, index) => {
                        return <IonItem key={ctp.id}>
                            <IonLabel>
                                <h4>
                                    Hole {ctp.hole}
                                </h4>
                            </IonLabel>
                            { !event.final && authValues.isCoordinator && <IonIcon slot="end" onClick={() => remove_ctp(ctp)} icon={removeCircleOutline}/>}
                        </IonItem>
                    })
                }
                </IonList>
            </IonContent>
            {
                !event.final && authValues.isCoordinator &&
                <IonFooter>
                    <IonToolbar>
                        <IonTitle>All Done?</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={handleDoneClick} routerDirection="back"
                                       routerLink={`/manage/details/${match.params.id}`}>Done</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            }
        </IonPage>
    );
};

export default ManageCtps;
