import {
    IonApp,
    IonButton,
    IonButtons, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList, IonLoading,
    IonPage,
    IonRow, IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {useFirestoreDocData, useFirestore} from "reactfire";
import './Splash.css';
import React, {Suspense, useContext, useEffect, useState} from "react";
import AuthContext from '../auth-context'
import {logOutOutline} from "ionicons/icons";
import useSchedule from "../hooks/schedule-provider";
import useEvents from "../hooks/events-provider";
import useStats from '../hooks/stats-provider';

const Splash: React.FC = () => {
    const {logout} = useContext(AuthContext);
    const [events]: any[] = useEvents();
    const [{api_schedule}]: any[] = useSchedule();
    const [{api_stats}]: any = useStats();
    const [event, setEvent]: any = useState();
    const [eventLink, setEventLink] = useState<string>();

    useEffect(() => {
        let live_events = events.filter((x: any) => x.final == false)
        if (live_events.length > 0) {
            var ev = live_events[0];
            setEvent(ev);
            setEventLink(`/manage/details/${ev.id}`);
        }
    }, [])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>NSB Disc Golf</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={logout} type="button" slot="end">
                            <IonIcon icon={logOutOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {
                    event && eventLink &&
                    <IonToolbar>
                        <IonItem className="ion-text-center" routerLink={eventLink}>
                            <IonTitle>View Live Event</IonTitle>
                        </IonItem>
                    </IonToolbar>
                }
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard className="ion-text-center">
                                {
                                    api_stats && api_stats.result &&
                                    <IonCardContent>
                                        <IonCardTitle>${api_stats.result.aceFund}</IonCardTitle>
                                        <IonCardSubtitle>Ace Fund</IonCardSubtitle>
                                    </IonCardContent>
                                }
                            </IonCard>
                        </IonCol>
                        <IonCol>
                            <IonCard className="ion-text-center">
                                {
                                    api_stats && api_stats.result &&
                                    <IonCardContent>
                                        <IonCardTitle>{api_stats.result.memberCount}</IonCardTitle>
                                        <IonCardSubtitle>2021 Members</IonCardSubtitle>
                                    </IonCardContent>
                                }
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonTitle className="ion-text-center">Club Events</IonTitle>
                <IonList>
                    {
                        api_schedule && api_schedule.map((scheduled_date: any, index: number) => {
                            return (
                                <IonItem key={scheduled_date.start}>
                                    <IonLabel>{scheduled_date.title}
                                        <p>{new Date(scheduled_date.start).toLocaleTimeString('en-US', {
                                            weekday: 'long',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}</p>
                                    </IonLabel>
                                </IonItem>
                            )
                        })
                    }
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Splash;
