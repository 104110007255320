import {
    IonAvatar,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonChip,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon, IonItem, IonItemOption, IonItemOptions,
    IonItemSliding, IonLabel,
    IonList,
    IonNote,
    IonPage, IonRow, IonText,
    IonTitle, IonToggle,
    IonToolbar
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import {useFirestoreDocData, useFirestore, SuspenseWithPerf} from "reactfire";
import React, {Suspense, useContext, useEffect, useState} from "react";
import AuthContext from '../auth-context'
import {checkmarkCircleOutline, home, removeCircleOutline} from "ionicons/icons";
import HeaderContainer from "../components/Header";
import {RouteComponentProps} from "react-router";
import useEvents from "../hooks/events-provider";
import useEvent from "../hooks/event-provider";
import usePayouts from "../hooks/payout-provider";

interface ManagePayoutPageProps extends RouteComponentProps<{
    id: string;
}> {
}

const ManagePayouts: React.FC<ManagePayoutPageProps> = ({match, history}) => {
    const {authValues} = useContext(AuthContext);
    const [{event, players, cards, ctps}, {savePayoutsProcessed, saveField, groupPlayersByScoreHandicaps, groupPlayersByScoreDoubles}]: any = useEvent(match.params.id);
    const [{handicaps, doubles}]: any = usePayouts();
    const [finalPayout, setFinalPayout] = useState<any[]>([]);
    
    useEffect(() => {
        // Detect and set processing state
        //@ts-ignore
        if (!event.final && authValues.isCoordinator) {
            saveField({"managePayoutsProcessed": "processing"});
        }
    }, [])

    useEffect(() => {
        event.type === "Doubles" ? group_players_by_score_doubles() : group_players_by_score_handicaps();
    }, [])
    
    const group_players_by_score_handicaps = () => {
        const final_payout_local = groupPlayersByScoreHandicaps(handicaps)
        setFinalPayout(final_payout_local);
    }

    const group_players_by_score_doubles = () => {
        const final_payout_local = groupPlayersByScoreDoubles(doubles);
        setFinalPayout(final_payout_local);
    }
    
    const handleDoneClick = () => {
        saveField({"managePayoutsProcessed": true});
    }

    const ctp_text = (ctp: any) => {
        if (ctp.winner) {
            return (<IonItem key={ctp.id}>
                Hole {ctp.hole}
                <IonChip slot="end">{ctp.winner && ctp.winner.firstName} {ctp.winner && ctp.winner.lastName}</IonChip>
            </IonItem>)
        } else {
            return (<IonItem key={ctp.id}>
                Hole {ctp.hole}
            </IonItem>)
        }
    }

    const chip_class = {
        fontSize: '12px'
    }

    const display_handicaps = () => {
        return finalPayout && finalPayout.map((player: any, index: any) => (
                <IonItem key={`player.firstName ${index}`}>
                    <IonBadge slot="start">{player.place}
                    </IonBadge>
                    <IonLabel>{player.firstName} {player.lastName}
                        <p>
                            <IonChip style={chip_class}>{player.rawScore} {player.handicap_sign} {player.handicap_absolute_value} = {player.adjustedScore}</IonChip>
                        </p>
                    </IonLabel>
                    {
                        player.amount && <IonChip slot="end">$ {player.amount}</IonChip>
                    }
                </IonItem>
            )
        )
    }
    
    const display_oddman_selector = () => {
        return ( players.length % 2 != 0 &&
            <IonCard>
                <IonCardContent>
                    <IonItem>
                        <IonLabel>Odd Man Paid Extra?</IonLabel>
                        <IonText>{event.oddman_paid_extra ? 'YES' : 'NO'}</IonText>
                        {/*<IonToggle disabled={true} checked={checked} onIonChange={(e) => {setChecked(e.detail.checked)}}/>*/}
                    </IonItem>
                    {
                        event.oddman_paid_extra && <IonNote>Payout includes odd man extra payment.</IonNote>
                    }
                </IonCardContent>
            </IonCard>
        )
    }
    
    const display_doubles = () => {
        return ( 
            finalPayout && finalPayout.map((group: any, index: any) => (
                <IonItem key={Math.random()}>
                    <IonBadge slot="start">{group.place}
                    </IonBadge>
                    <IonLabel className="ion-text-wrap">
                        {group && group.playerOne && group.playerOne.firstName} {group && group.playerOne && group.playerOne.lastName} { group && group.playerTwo && "/" } {group && group.playerTwo && group.playerTwo.firstName} {group && group.playerTwo && group.playerTwo.lastName} {group && !group.playerTwo && "- ODD MAN"}
                        <p>
                            <IonChip style={chip_class}>Score: {group.rawScore}</IonChip>
                        </p>
                    </IonLabel>
                    {
                        group.amount && <IonChip slot="end">$ {group.amount}</IonChip>
                    }
                </IonItem>
            )
        )
        )
    }
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/manage/details/${match.params.id}`}/>
                    </IonButtons>
                    <IonTitle size="large">Results & Payouts</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding" fullscreen>
                <Suspense fallback={'loading event results...'}>
                    <IonCard>
                        <IonCardHeader>CTPs - ${players && players.length} each</IonCardHeader>
                        <IonCardContent>
                            {
                                ctps && ctps.map((item: any, index: any) => (
                                        ctp_text(item)
                                    )
                                )
                            }
                        </IonCardContent>
                    </IonCard>
                    {
                        event.type === "Doubles" ? display_oddman_selector() : null
                    }
                    <IonCard>
                        <IonCardHeader>Payouts</IonCardHeader>
                        <IonCardContent>
                            <IonList>
                                {
                                    event.type === "Doubles" ? display_doubles() : display_handicaps()
                                }
                            </IonList>
                        </IonCardContent>
                    </IonCard>
                </Suspense>
            </IonContent>
            {
                !event.final && authValues.isCoordinator &&
                <IonFooter>
                    <IonToolbar>
                        <IonTitle>All Done?</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={handleDoneClick} routerDirection="back"
                                       routerLink={`/manage/details/${match.params.id}`}>Done</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            }
        </IonPage>
    );
};

export default ManagePayouts;
