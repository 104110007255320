import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow, useIonToast
} from '@ionic/react';
import logo from '../assets/club_logo_512x512.png'
import React, {Suspense, useContext, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {PrecacheController} from "workbox-precaching";
import {useAuth, SuspenseWithPerf,} from "reactfire";
import AuthContext from '../auth-context'
import firebase from "firebase";

const LoginForm: React.FC = () => {
    const {login} = useContext(AuthContext);
    const auth = useAuth();
    const {
        control, setValue, handleSubmit, formState: {
            errors,
            isDirty,
            isValid
        }
    } = useForm({mode: "onChange"});
    const [present, dismiss] = useIonToast();

    const handleLoginSubmit = (values: any) => {
        // Do login
        console.log(values);
        // auth.signInWithEmailAndPassword(values.username, values.password)
    }

    const facebookSignIn = () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        auth.signInWithPopup(provider).then((result) => {
            // TODO populate auth context with values and redirect to home page
            login(result.user);
        }, (err) => {
            present({
                buttons: [{text: 'close', handler: () => dismiss()}],
                message: err.message
            })
        })
    }

    const googleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider).then((result) => {
            login(result.user);
        }, (err) => {
            present({
                buttons: [{text: 'close', handler: () => dismiss()}],
                message: err.message
            })
        })
    }
    
    const facebook_button_style = {
        "background": "#3675e3",
        "color": "white"
    }

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <IonGrid>
                    {/*<form onSubmit={handleSubmit(handleLoginSubmit)}>*/}
                    <IonRow>
                        <IonCol>
                            <IonImg style={{"paddingLeft": "15vw", "maxWidth": "73vw"}} alt="club logo" src={logo}/>
                        </IonCol>
                    </IonRow>
                    <IonRow style={{"textAlign": "center"}}>
                        <IonCol className="text-center">
                            <h2>Sign in</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton type="button" expand="full" style={facebook_button_style} onClick={facebookSignIn}>Facebook</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton color="danger" type="button" expand="full"
                                       onClick={googleSignIn}>Google</IonButton>
                        </IonCol>
                    </IonRow>
                    {/*<IonRow>*/}
                    {/*    <IonCol>*/}
                    {/*        <IonItem>*/}
                    {/*            <IonLabel position="floating">Username</IonLabel>*/}
                    {/*            <Controller*/}
                    {/*                render={(*/}
                    {/*                    {field: {onChange, onBlur, value}}*/}
                    {/*                ) => (<IonInput value={value} onIonChange={onChange} onIonBlur={onBlur}/>)}*/}
                    {/*                name='username'*/}
                    {/*                control={control}*/}
                    {/*                rules={{required: true}}*/}
                    {/*            />*/}
                    {/*        </IonItem>*/}
                    {/*    </IonCol>*/}
                    {/*</IonRow>*/}
                    {/*<IonRow>*/}
                    {/*    <IonCol>*/}
                    {/*        <IonItem>*/}
                    {/*            <IonLabel position="floating">Password</IonLabel>*/}
                    {/*            <Controller*/}
                    {/*                render={(*/}
                    {/*                    {field: {onChange, onBlur, value}}*/}
                    {/*                ) => (<IonInput type="password" value={value} onIonChange={onChange}*/}
                    {/*                                onIonBlur={onBlur}/>)}*/}
                    {/*                name='password'*/}
                    {/*                control={control}*/}
                    {/*                rules={{required: true}}*/}
                    {/*            />*/}
                    {/*        </IonItem>*/}
                    {/*    </IonCol>*/}
                    {/*</IonRow>*/}
                    {/*<IonRow>*/}
                    {/*    <IonCol>*/}
                    {/*        <IonButton disabled={!isDirty || !isValid} type="submit" color="primary" expand="full"*/}
                    {/*                   className="ion-padding">Login</IonButton>*/}
                    {/*    </IonCol>*/}
                    {/*</IonRow>*/}
                    {/*<hr />*/}
                    {/*</form>*/}
                </IonGrid>
            </IonContent>
        </IonPage>

    );
}

const Login: React.FC = () => {
    return (
        <Suspense fallback={'loading login page...'}>
            <LoginForm/>
        </Suspense>
    )
}

export default Login;