import React, { useCallback } from 'react';
import {useFirestoreCollectionData, useFirestore, useFirestoreDocData} from 'reactfire';
import useTenantProvider from "./tenant-provider";

function useCtps(event_id: any)  {
    const tenant = useTenantProvider();
    // lazy load the Firestore SDK
    const ref = useFirestore().collection(`tenants/${tenant}/event`).doc(event_id).collection('ctps');

    // subscribe to the collection
    const { data: ctps } = useFirestoreCollectionData(ref.orderBy('hole', 'asc'), {idField: 'id'});
    
    // create handlers
    async function addCtp(ctp: any){
        return await ref.add(ctp);
    }
    async function saveCtp(ctp: any){
        await ref.doc(ctp.id).set(ctp, { merge: true });
    }
    async function deleteCtp(ctp_id: any){
        return await ref.doc(ctp_id).delete();
    }

    const handlers = {
        addCtp: useCallback(addCtp, [ref]),
        saveCtp: useCallback(saveCtp, [ref]),
        deleteCtp: useCallback(deleteCtp, [ref]),
    }

    // return array of [data, handlers] to match hooks like useState
    return [ctps, handlers];
}

export default useCtps;