import {
    IonButton,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonDatetime,
    IonItemSliding,
    IonItemOption,
    IonItemOptions, useIonAlert, IonSelectOption, IonSelect, IonText, IonIcon, IonBackButton, IonToggle, useIonLoading, IonPopover
} from '@ionic/react';

import './Manage.css';
import useEvents from "../hooks/events-provider";
import React, {useState, useEffect, useRef, useImperativeHandle, Suspense, useContext} from "react";
import {Controller, Resolver, SubmitHandler, useController, useForm} from 'react-hook-form';
import IEvent from "../types/event";
import useCourses from "../hooks/course_provider";
import {checkmarkCircleOutline, closeCircle, removeCircleOutline} from 'ionicons/icons';
import HeaderContainer from "../components/Header";
import AuthContext from "../auth-context";

function EventsList({listRef, editEvent, resetItemsHandler}: any) {
    const {authValues} = useContext(AuthContext);
    // @ts-ignore
    const [events, {addEvent, removeEvent}] = useEvents();
    const [present] = useIonAlert();

    const removeEventHandler = (event: any) => {
        removeEvent(event.id);
    }

    const editEventHandler = (event: any) => {
        editEvent(event)
    }
    
    const event_final_style = {
        color: 'green'
    }
    const event_open_style = {
        color: 'red'
    }
    
    const event_status_display = (event: any) => {
        return <IonIcon style={event.final ? event_final_style : event_open_style} icon={event?.final ? checkmarkCircleOutline : removeCircleOutline}/>
    }

    return <IonList ref={listRef}>{
        // @ts-ignore
        events && events.map((event: IEvent, _: number) => {
            const link = `/manage/details/${event.id}`;
            return (
                <IonItemSliding key={event.id}>
                    <IonItem routerLink={link}>
                        {
                            event && event_status_display(event)
                        }
                        <IonLabel>&nbsp;{event.name}</IonLabel>
                        <IonText slot="end">{new Date(event.date).toLocaleDateString()}</IonText>
                    </IonItem>

                    {
                        authValues.isCoordinator &&
                        <IonItemOptions side="start">
                            <IonItemOption color="danger" onClick={() =>
                                present({
                                    header: 'Are You Sure?',
                                    message: 'Deleting an event cannot be undone',
                                    buttons: [
                                        'Cancel',
                                        {text: 'Delete', handler: (d) => removeEventHandler(event)}
                                    ],
                                    onDidDismiss: (e) => resetItemsHandler()
                                })}>Delete</IonItemOption>
                        </IonItemOptions>
                    }

                    <IonItemOptions side="end">
                        <IonItemOption color="primary" onClick={() => editEventHandler(event)}>Edit</IonItemOption>
                    </IonItemOptions>
                </IonItemSliding>
            )
        })
    }</IonList>;
}

function EventModal({closeModalHandler, showModal, event}: any) {
    // @ts-ignore
    const [events, {addEvent, saveEvent}] = useEvents();
    const [checked, setChecked] = useState(false);
    const {data} = useCourses();
    const {
        getValues,
        setValue,
        handleSubmit,
        control,
        reset,
        formState: {
            errors, isDirty, isSubmitting, submitCount, isValid
        }
    } = useForm({mode: "onChange"});

    useEffect(() => {
        if (event) {
            setValue('name', event.name, {shouldValidate: true, shouldDirty: false})
            setValue('date', event.date, {shouldValidate: true, shouldDirty: false})
            setValue('course_id', event.course_id, {shouldValidate: true, shouldDirty: false})
            setValue('type', event.type, {shouldValidate: true, shouldDirty: false})
            // Use state to track checked value
            setChecked(event.is_war_qualifier ? event.is_war_qualifier : false);
        }
    }, [setValue, event])
    
    
    const modalCloseHandler = () => {
        reset();
        closeModalHandler();
    }
    
    const handleDate = (value: any) => {
        setValue('date', value, {shouldValidate: true, shouldDirty: false})
    }

    const modalSubmitHandler = (data: any, e: any) => {
        if (!!event) {
            var ev: any = {
                id: event.id,
                name: data.name,
                date: data.date,
                course_id: data.course_id,
                type: data.type,
                is_war_qualifier: checked,
                final: event?.final ?? false
            }
            saveEvent(ev);
        } else {
            data.is_war_qualifier = checked;
            addEvent(data)
        }
        modalCloseHandler();
    }

    return <IonModal isOpen={showModal} >
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Add Event</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={modalCloseHandler}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
            <IonGrid>
                <form onSubmit={handleSubmit(modalSubmitHandler)}>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="floating">Name</IonLabel>
                                <Controller
                                    render={(
                                        {field: {onChange, onBlur, value}}
                                    ) => (<IonInput value={value} onIonChange={onChange} onIonBlur={onBlur}/>)}
                                    name='name'
                                    control={control}
                                    rules={{required: true}}
                                />
                            </IonItem>
                            {errors.name && errors.name?.type === "required" &&
                            <span style={{color: "red"}}>Name is required!</span>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem button={true} id="open-date-input">
                                <IonLabel position="floating">Date</IonLabel>
                                <p>
                                    {new Date(getValues("date")).toLocaleDateString()}
                                </p>
                                <IonPopover trigger="open-date-input" showBackdrop={false} size="cover">
                                    <Controller
                                        render={(
                                            {field: {onChange, onBlur, value}}
                                        ) => (<IonDatetime presentation="date" onIonChange={(e: any) => handleDate(e.detail.value)} onIonBlur={onBlur} value={value}/>)}
                                        name='date'
                                        control={control}
                                        rules={{required: true}}
                                    />
                                </IonPopover>
                            </IonItem>
                            {errors.date && errors.date?.type === "required" &&
                            <span style={{color: "red"}}>Date is required!</span>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Course</IonLabel>
                                <Controller
                                    render={(
                                        {field: {onChange, onBlur, value}}
                                    ) => (<IonSelect value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                        {data.map((item: any) => (
                                            <IonSelectOption key={item.id}
                                                             value={item.id}>{item.name}</IonSelectOption>)
                                        )}
                                    </IonSelect>)}
                                    name='course_id'
                                    control={control}
                                    rules={{required: true}}/>
                            </IonItem>
                            {errors.course_id && errors.course_id?.type === "required" &&
                            <span style={{color: "red"}}>Course is required!</span>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Event Type</IonLabel>
                                <Controller
                                    render={(
                                        {field: {onChange, onBlur, value}}
                                    ) => (<IonSelect value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                        <IonSelectOption key="handicaps" value="Handicaps">Handicaps</IonSelectOption>
                                        <IonSelectOption key="doubles" value="Doubles">Doubles</IonSelectOption>
                                        <IonSelectOption key="scratch" value="Scratch">Scratch</IonSelectOption>
                                    </IonSelect>)}
                                    name='type'
                                    control={control}
                                    rules={{required: true}}/>
                            </IonItem>
                            {errors.type && errors.type?.type === "required" &&
                            <span style={{color: "red"}}>Event Type is required!</span>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Is War Qualifier</IonLabel>
                                <Controller
                                    render={(
                                        {field: {onChange, onBlur, value}}
                                    ) => (<IonToggle checked={checked} onIonChange={(e) => {
                                        setChecked(e.detail.checked)}}
                                                     onIonBlur={onBlur} />)}
                                    name='is_war_qualifier'
                                    control={control}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-text-center">
                        <IonCol>
                            <IonButton disabled={!isDirty || !isValid} type="submit" expand="full"
                                       className="ion-padding">Submit</IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            </IonGrid>
        </IonContent>
    </IonModal>
}

const Manage: React.FC = () => {
    const {authValues} = useContext(AuthContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [event, setEvent] = useState(null);
    const eventsListRef = useRef<HTMLIonListElement>();

    const editEventHandler = (event: any) => {
        // get courses
        setEvent(event);
        setShowModal(true);
    }

    const resetSlidingItems = () => {
        eventsListRef.current?.closeSlidingItems();
    }

    const closeModalHandler = () => {
        setEvent(null);
        // Close the ion-slider in the EventList component
        resetSlidingItems();
        setShowModal(false);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/"} />
                    </IonButtons>
                    <IonTitle size="large">Events</IonTitle>
                    {
                        authValues.isCoordinator && 
                        <IonButtons slot="end">
                            <IonButton type="button" onClick={() => setShowModal(true)}>Add Event</IonButton>
                        </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <Suspense fallback={"event modal loading..."}>
                    {
                        authValues.isCoordinator && 
                        <EventModal closeModalHandler={closeModalHandler} event={event} showModal={showModal}
                        />
                    }
                </Suspense>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Events</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <Suspense fallback={'loading events...'}>
                    <EventsList resetItemsHandler={resetSlidingItems} listRef={eventsListRef}
                                editEvent={editEventHandler}/>
                </Suspense>
            </IonContent>
        </IonPage>
    );
};

export default Manage;
