import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCol,
    IonContent, IonFooter,
    IonGrid,
    IonHeader,
    IonIcon, IonItem, IonItemOption, IonItemOptions,
    IonItemSliding, IonLabel,
    IonList,
    IonModal,
    IonPage, IonRippleEffect, IonRow, IonSearchbar, IonText,
    IonTitle,
    IonToolbar, useIonAlert, useIonLoading, useIonPopover, useIonToast
} from '@ionic/react';
import React, {useContext, useEffect, useRef, useState} from "react";
import {checkmarkCircleOutline, home, menuOutline, removeCircleOutline} from "ionicons/icons";
import HeaderContainer from "../components/Header";
import {RouteComponentProps} from "react-router";
import usePlayers from "../hooks/player-provider";
import useEvent from "../hooks/event-provider";
import useCards from "../hooks/card-provider";
import AuthContext from "../auth-context";

interface ManagePlayersPageProps extends RouteComponentProps<{
    id: string;
}> {
}

function SelectPlayerModal({playerHandicapComponent, closeModalHandler, removePlayerHandler, showModal, eventId, players}: any) {
    // const [{api_players}]: any = usePlayers();
    const [event, {addPlayer, saveField}]: any = useEvent(eventId);
    const [cards, {saveCard, deleteCard}]: any = useCards(eventId);
    // const [players, setPlayers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedPlayer, setSelectedPlayer]: any = useState();
    const [showAddPlayerAlert, setShowAddPlayerAlert] = useState<boolean>(false);
    const searchRef = useRef<HTMLIonSearchbarElement>();
    const [present, dismiss] = useIonToast();
    const [presentLoader, dismissLoader] = useIonLoading();

    useEffect(() => {
        searchRef.current?.focus();
    }, [])

    const closeHandler = () => {
        setSearchText('');
        closeModalHandler();
    }
    
    const undoAdd = (player: any) => {
        removePlayerHandler(player);   
    }

    const selectHandler = () => {
        if (!selectedPlayer){
            return;
        }
        
        // show overlay
        presentLoader({message: 'Adding Player...'}).then(() => {
            // Add player to the event
            addPlayer(selectedPlayer);
            setSearchText('');

            // If there are existing cards on the event, add the new player to the first card
            if (event.event.type !== "Doubles" && cards && cards.length > 0) {
                cards[0].players.push(selectedPlayer);
                saveCard(cards[0]);
            }

            // If a player is added for doubles, that means a REDRAW. Wipe the cards
            if (event.event.type === "Doubles"){
                if (cards) {
                    // @ts-ignore
                    cards.forEach((card: any) => {
                        deleteCard(card.id);
                    })
                    saveField({"manageCardsProcessed": false});
                    saveField({"manageHoleAssignmentsProcessed": false});
                }
            }

        }).finally(() => {
            dismissLoader().then(() => {
                present({
                    buttons: [{text: 'undo', handler: () => undoAdd(selectedPlayer)}],
                    message: `${selectedPlayer.firstName} ${selectedPlayer.lastName} has been added!`,
                    duration: 2000
                })
            });
        });
    }
    
    const playerSelectedToAdd = (player: any) => {
        setSelectedPlayer(player);
        setShowAddPlayerAlert(true);
    }
    
    return <IonModal isOpen={showModal}>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Select Player</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={closeHandler}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className="ion-padding" fullscreen>
                <IonAlert
                    isOpen={showAddPlayerAlert}
                    onDidDismiss={() => setShowAddPlayerAlert(false)}
                    cssClass='my-custom-class'
                    header={`${selectedPlayer && selectedPlayer.firstName} ${selectedPlayer && selectedPlayer.lastName}`}
                    message={`Add ${selectedPlayer && selectedPlayer.firstName} ${selectedPlayer && selectedPlayer.lastName} to event?`}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: (() => setShowAddPlayerAlert(false))
                        },
                        {
                            text: 'Ok',
                            handler: selectHandler
                        }
                    ]}
                />
                <IonSearchbar inputMode="text" value={searchText} onIonChange={e => setSearchText(e.detail.value!)}/>
                <IonList>
                    {
                        players && players
                            .filter((x: any) => !event.players.find((p: any) => p.playerId == x.playerId))
                            .filter((x: any) => x.firstName && x.firstName.toLowerCase().includes(searchText.toLowerCase()) || x.lastName && x.lastName.toLowerCase().includes(searchText.toLocaleLowerCase())).map((item: any, index: number) => {
                                    return (
                                        <IonItem key={item.playerId} onClick={() => playerSelectedToAdd(item)}>
                                            <IonLabel>{item.firstName} {item.lastName}</IonLabel>
                                            {playerHandicapComponent(item)}
                                        </IonItem>
                                    )
                                }
                            )}
                </IonList>
            </IonContent>
        </IonHeader>
    </IonModal>
}

const ManagePlayers: React.FC<ManagePlayersPageProps> = ({match, history}) => {
    const {authValues} = useContext(AuthContext);
    // @ts-ignore
    const [event, {addPlayer, removePlayer, saveField, removePlayerByPlayerId}]: any[] = useEvent(match.params.id);
    const [{api_players}, {addNewPlayer, getCourseHandicapsByCourse}]: any = usePlayers(event.event.course_id);
    const [players, setPlayers] = useState<any[]>([]);
    // @ts-ignore
    const [cards, {saveCard, deleteCard}]: any[] = useCards(match.params.id);
    const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
    const [showNewPlayerAlert, setShowNewPlayerAlert] = useState(false);
    const [present, dismiss] = useIonAlert();
    const [presentToast, dismissToast] = useIonToast();

    useEffect(() => {
        console.log(event.event);
        // Detect and set processing state
        //@ts-ignore
        if (!event.event.final && authValues.isCoordinator) {
            saveField({"managePlayersProcessed": "processing"});
        }
    }, [])
    
    useEffect(() => {
        if (event.event.course_id){
            getCourseHandicapsByCourse(event.event.course_id);
        }
    }, [event.event.course_id])

    useEffect(() => {
        setPlayers(api_players);
    }, [api_players])

    const closeModalHandler = () => {
        setShowAddPlayerModal(false);
    }
    
    const removePlayerHandler = (player: any) => {
        remove_player(player);
    }
    
    const remove_player = (player: any) => {
        removePlayerByPlayerId(player.playerId);
        // removePlayer(player.playerId);
        // remove from card if exists
        if (!cards) {
            return;
        }

        // Nuke the cards any time a player change is made
        if (event.event.type === "Doubles"){
            if (cards) {
                // @ts-ignore
                cards.forEach((card: any) => {
                    deleteCard(card.id);
                })
                saveField({"manageCardsProcessed": false});
                saveField({"manageHoleAssignmentsProcessed": false});
            }
        } else {
            cards.forEach((card: any, c_index: number) => {
                card.players.forEach((p: any, s_index: number) => {
                    if (p.playerId == player.playerId) {
                        const existing_players = card.players;
                        const new_players = existing_players.filter((x: any) => x.playerId != card.players[s_index].playerId);
                        card.players = new_players;
                        saveCard(card);
                    }
                })
            })
        }

        presentToast({
            message: `${player.firstName} ${player.lastName} has been removed!`,
            duration: 2000
        })
    }
    

    const handleDoneClick = () => {
        saveField({"managePlayersProcessed": true});
    }
    
    const isNumber = (number: any) => {
        return typeof number === "number"
    }

    const playerHandicap = (player: any) => {
        // @ts-ignore
        if (event.event.type != 'Handicaps') {
            return;
        }

        // @ts-ignore
        if (player.handicap == undefined || !isNumber(player.handicap)) {
            return (<i>est</i>);
        }
        if (player.handicap > 0) {
            return (
                <IonBadge>+{player.handicap}</IonBadge>
            )
        } else if (player.handicap < 0) {
            return (
                <IonBadge>{player.handicap}</IonBadge>
            )
        }
        return (
            <IonBadge>{player.handicap}</IonBadge>
        )
    }

    const addNewPlayerHandler = (item: any) => {
        const playerAlreadyAdded = event.players.find((x: any) => x.firstName == item.firstName && x.lastName == item.lastName);
        if (playerAlreadyAdded) {
            return;
        }
        
        const playerFromServer = api_players.find((x: any) => x.firstName == item.firstName && x.lastName == item.lastName);
        if (playerFromServer){
            addPlayer(playerFromServer);
        }
        else {
            const result = addNewPlayer(item).then((res: any) => {
                // add to state object
                setPlayers(players => [...players, res]);
                // add to event
                addPlayer(res);
            });
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/manage/details/${match.params.id}`}/>
                    </IonButtons>
                    <IonTitle size="large">Players</IonTitle>
                    {
                        !event.event.final && authValues.isCoordinator &&
                        <IonButtons slot="end">
                            <IonButtons slot="end">
                                <IonButton onClick={() => setShowNewPlayerAlert(true)} type="button" slot="end">
                                    Create Player
                                    {/*<IonIcon icon={menuOutline}/>*/}
                                </IonButton>
                            </IonButtons>
                        </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding" fullscreen>
                <IonAlert
                    isOpen={showNewPlayerAlert}
                    onDidDismiss={() => setShowNewPlayerAlert(false)}
                    cssClass='my-custom-class'
                    header={'Create a new player'}
                    inputs={[
                        {
                            name: 'firstName',
                            type: 'text',
                            placeholder: 'First Name'
                        },
                        {
                            name: 'lastName',
                            type: 'text',
                            placeholder: 'Last Name'
                        }
                    ]}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                setShowNewPlayerAlert(false);
                            }
                        },
                        {
                            text: 'Ok',
                            handler: addNewPlayerHandler
                        }
                    ]}
                />
                {
                    !event.event.final && authValues.isCoordinator &&
                    <SelectPlayerModal players={players} playerHandicapComponent={playerHandicap}
                                       eventId={match.params.id}
                                       closeModalHandler={closeModalHandler}
                                       removePlayerHandler = {removePlayerHandler}
                                       showModal={showAddPlayerModal}/>
                }
                {
                    !event.event.final && authValues.isCoordinator &&
                    <IonButton type="button" expand="full" onClick={() => setShowAddPlayerModal(true)}>Select
                        Players</IonButton>
                }
                <IonList>{
                    // @ts-ignore
                    event && event.players && event.players.map((player, index) => {
                        return <IonItem key={player.playerId}>
                            <IonLabel>
                                <h4>
                                    {player.firstName} {player.lastName}
                                </h4>
                            </IonLabel>
                            {playerHandicap(player)}
                            {
                                !event.event.final && authValues.isCoordinator &&
                                <IonIcon slot="end" onClick={() => remove_player(player)} icon={removeCircleOutline}/>
                            }
                        </IonItem>
                    })
                }
                </IonList>
            </IonContent>
            {
                !event.event.final && authValues.isCoordinator &&
                <IonFooter>
                    <IonToolbar>
                        <IonTitle>All Done?</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={handleDoneClick} routerDirection="back"
                                       routerLink={`/manage/details/${match.params.id}`}>Done</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            }
        </IonPage>
    );
};

export default ManagePlayers;
