import {
    IonAvatar,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent, IonFooter,
    IonHeader,
    IonIcon, IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonNote,
    IonPage, IonSelect, IonSelectOption, IonText,
    IonTitle, IonToggle,
    IonToolbar, useIonPopover
} from '@ionic/react';
import React, {Fragment, Suspense, useContext, useEffect, useRef, useState} from "react";
import AuthContext from '../auth-context'
import {
    enterOutline,
    menuOutline,
} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import useEvent from "../hooks/event-provider";
import useCards from "../hooks/card-provider";
import usePlayers from "../hooks/player-provider";

interface ManageCardsPageProps extends RouteComponentProps<{
    id: string;
}> {
}

function CardsHelpModal({closeModalHandler, showModal}: any) {
    const closeHandler = () => {
        closeModalHandler();
    }

    return <IonModal isOpen={showModal}>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Help With Cards</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={closeHandler}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className="ion-padding" fullscreen>
                <IonText>
                    <IonNote>The cards page is where you will organize players into groups.</IonNote>
                    <h4>Initial setup</h4>
                    <ol>
                        <li>Select the desired 'Players Per Card'. This defaults to 4.</li>
                        <li>Click 'Shuffle' - This will randomly assign players into groups.</li>
                    </ol>
                    <h4>Changing Groups</h4>
                    <p>You can move players between groups by clicking a player, then clicking where you want to move
                        them to.</p>
                </IonText>
            </IonContent>
        </IonHeader>
    </IonModal>
}

const PopoverList: React.FC<{
    onHide: () => void;
    shuffleCards: () => void;
    addCard: () => void;
    showHelp: () => void;
}> = ({onHide, shuffleCards, addCard, showHelp}) => {

    const handleShuffleCards = () => {
        onHide();
        shuffleCards();
    }
    const handleAddCard = () => {
        onHide();
        addCard();
    }
    const handleShowHelp = () => {
        onHide();
        showHelp();
    }

    return (
        <IonList>
            <IonItem button onClick={handleAddCard}>Add Card</IonItem>
            <IonItem button onClick={handleShowHelp}>Show Help</IonItem>
            <IonItem button onClick={handleShuffleCards}>Shuffle Cards</IonItem>
            <IonItem lines="none" detail={false} button onClick={onHide}>
                Close
            </IonItem>
        </IonList>
    )
};

const ManageCardsDoubles: React.FC<ManageCardsPageProps> = ({match, history}) => {
        const {authValues} = useContext(AuthContext);
        const [{event, players}, {saveField}]: any = useEvent(match.params.id);
        const [{api_players}, {getCourseHandicapsByCourse}]: any = usePlayers(event.course_id);
        // @ts-ignore
        const [cards, {addCard, saveCard, addPlayerToCard, deleteCard}]: any[] = useCards(match.params.id);
        // const [_cards, set_cards] = useState<any>([]);
        const [gpc, setGpc] = useState(2);
        const [ppc, setPpc] = useState(4);
        const [selectedPlayer, setSelectedPlayer] = useState<any>(null);
        const [group, setGroup]: any[] = useState([]);
        const [showHelpModal, setShowHelpModal] = useState(false);
        const [checked, setChecked] = useState(false);

        useEffect(() => {
            setChecked(event.oddman_paid_extra ? event.oddman_paid_extra : false);
        }, [event])

        useEffect(() => {
            getCourseHandicapsByCourse(event.course_id);
        }, [])

        useEffect(() => {
            // Detect and set processing state
            //@ts-ignore
            if (!event.final && !event.manageCardsProcessed && authValues.isCoordinator) {
                saveField({"manageCardsProcessed": "processing"});
            }
        }, [])

        const update_oddman_paid_extra = (value: boolean) => {
            setChecked(value);
            saveField({"oddman_paid_extra": value});
        }
        // useEffect(() => {
        //     cards && cards.forEach((item: any, _: number) => {
        //         let grps: any[] = [];
        //         item.players && item.players.forEach((player: any, index: number) => {
        //             grps[player.group_number].push(player);
        //         })
        //         cards.groups.push(grps);
        //     })
        //    
        // }, [cards])

        const addEmptyCard = () => {
            addCard({
                // @ts-ignore
                'ordinal': cards.length,
                // @ts-ignore
                'name': `Card ${cards.length + 1}`,
                'players': []
            })
        }

        const handleDoneClick = () => {
            saveField({"manageCardsProcessed": true});
        }

        const closeModalHandler = () => {
            setShowHelpModal(false);
        }

        function shuffle(array: any) {
            var currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }

            return array;
        }

        const makeCards = async () => {
            let card_count = Math.ceil(players.length / ppc);
            let new_cards: any[] = [];
            // Setup empty cards
            for (let i = 0; i < card_count; i++) {
                new_cards.push([]);
            }

            // shuffle players to make random partners
            const shuffled_players = shuffle(players);

            // Group players as partners
            let group_number = 0;
            let groups: any[] = [];
            let group: any = {};
            for (let i = 0; i < shuffled_players.length; i++) {
                if (i % 2 == 0) {
                    group.playerOne = shuffled_players[i];
                    // Last player who is odd-man, save to the groups array
                    if (i == shuffled_players.length - 1) {
                        group.playerTwo = api_players.find((x: any) => x.firstName === 'ODD' && x.lastName == 'MAN');
                        groups.push(group);
                    }
                } else {
                    group.playerTwo = shuffled_players[i];
                    groups.push(group);
                    group_number++;
                    group = {};
                }
            }

            // assign groups to card
            let c = 0;
            // Populate cards fully so there are empty spots to be filled
            for (let i = 0; i < new_cards.length * gpc; i++) {
                // @ts-ignore
                if (groups[i]) {
                    new_cards[c].push(groups[i])
                }

                if ((i + 1) % gpc == 0) {
                    c++;
                }
            }

            // nuke existing cards in firebase
            if (cards) {
                // @ts-ignore
                cards.forEach((card: any) => {
                    deleteCard(card.id);
                })
            }

            // persist cards to firebase
            new_cards.forEach((item, index) => {
                addCard({
                    'ordinal': index,
                    'name': `Card ${index + 1}`,
                    'players': new_cards[index]
                })
            })
        }

        const showHelp = () => {
            setShowHelpModal(true);
        }

        const [present, dismiss] = useIonPopover(PopoverList, {
            onHide: () => dismiss(),
            addCard: addEmptyCard,
            shuffleCards: makeCards,
            showHelp: showHelp
        })

        const processAddPlayerToCard = (target_card: number) => {
            if (!selectedPlayer) {
                return;
            }

            // move player to new card
            // @ts-ignore
            cards[target_card].players.push(cards[selectedPlayer.card].players[selectedPlayer.slot]);

            // remove player from old card
            // @ts-ignore
            cards[selectedPlayer.card].players.splice(selectedPlayer.slot, 1);
            setSelectedPlayer(null);

            // @ts-ignore
            cards.forEach((card: any, index: number) => {
                saveCard(card);
            });
        }

        const processSwap = (card: number, slot: number) => {
            // only allow an authorized user to make these changes
            if (event.final || !authValues.isCoordinator) {
                return;
            }
            // if selectedPlayer is null, this is the 'from' selection
            if (!selectedPlayer) {
                setSelectedPlayer({'card': card, 'slot': slot})
                return;
            }

            // Save the originally selected player data, then make the swap
            // @ts-ignore
            var temp_player = cards[selectedPlayer.card].players[selectedPlayer.slot];
            // @ts-ignore
            cards[selectedPlayer.card].players[selectedPlayer.slot] = cards[card].players[slot];
            // @ts-ignore
            cards[card].players[slot] = temp_player;
            setSelectedPlayer(null);

            // @ts-ignore
            cards.forEach((card: any) => {
                saveCard(card);
            })
        }

        const group_item = (card: number, slot: number) => {
            // @ts-ignore
            if (!cards.length > 0) {
                return;
            }
            // @ts-ignore
            const group = cards[card].players[slot];
            const is_selected_player = selectedPlayer && selectedPlayer.card == card && selectedPlayer.slot == slot;
            if (is_selected_player) {
                return (
                    <IonItem key={card * slot} onClick={() => processSwap(card, slot)}>
                        {group && group.playerOne.firstName} {group && group.playerOne.lastName} {group && group.playerTwo && "/"} {group && group.playerTwo && group.playerTwo.firstName} {group && group.playerTwo && group.playerTwo.lastName} {group && !group.playerTwo && "- ODD MAN"}
                        <IonIcon icon={enterOutline} slot="end"/>
                    </IonItem>
                )
            }

            return (
                <IonItem key={group && group.id || Math.random()} onClick={() => processSwap(card, slot)}>
                    {group && group.playerOne && group.playerOne.firstName} {group && group.playerOne && group.playerOne.lastName} {group && group.playerTwo && "/"} {group && group.playerTwo && group.playerTwo.firstName} {group && group.playerTwo && group.playerTwo.lastName} {group && !group.playerTwo && "- ODD MAN"}
                </IonItem>
            )
        }

        const display_oddman_selector = () => {
            return (players.length % 2 != 0 &&
                <IonCard>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel>Odd Man Paid Extra?</IonLabel>
                            <IonToggle disabled={event.final} checked={checked} onIonChange={(e) => {
                                update_oddman_paid_extra(e.detail.checked)
                            }}/>
                        </IonItem>
                    </IonCardContent>
                </IonCard>
            )
        }

        const update_grp = (value: number) => {
            setPpc(value * 2);
            setGpc(value);
        }


        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonButtons slot="start">
                            <IonBackButton defaultHref={`/manage/details/${match.params.id}`}/>
                        </IonButtons>
                        <IonTitle size="large">Doubles Cards</IonTitle>
                        {
                            !event.final && authValues.isCoordinator &&
                            <IonButtons slot="end">
                                <IonButton onClick={(e) =>
                                    present({
                                        event: e.nativeEvent,
                                    })
                                } type="button" slot="end">
                                    <IonIcon icon={menuOutline}/>
                                </IonButton>
                            </IonButtons>
                        }
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    {
                        !event.final && authValues.isCoordinator &&
                        <CardsHelpModal showModal={showHelpModal} closeModalHandler={closeModalHandler}/>
                    }
                    <Suspense fallback={'loading event details...'}>
                        <IonToolbar>
                            <IonTitle>{players && players.length} Players</IonTitle>
                            {
                                !event.final && authValues.isCoordinator &&
                                <IonButtons slot="end">
                                    <IonButton fill="solid" color="primary" onClick={makeCards}>Shuffle</IonButton>
                                </IonButtons>
                            }
                        </IonToolbar>
                        {
                            !event.final && authValues.isCoordinator &&
                            <IonItem>
                                <IonLabel>Groups per Card</IonLabel>
                                <IonSelect interface="popover" value={gpc}
                                           onIonChange={(e: any) => update_grp(e.detail.value)}>
                                    <IonSelectOption value="2">2</IonSelectOption>
                                    <IonSelectOption value="3">3</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        }
                        {
                            authValues.isCoordinator && event.type === "Doubles" ? display_oddman_selector() : null
                        }
                        {
                            // @ts-ignore
                            cards && cards.map((card: any, card_index: number) => {
                                    return (
                                        <IonCard key={card_index}>
                                            <IonItem>
                                                <IonTitle
                                                    onClick={() => processAddPlayerToCard(card_index)}>{card.name}</IonTitle>
                                                {/*<IonIcon onClick={() => addSlot(index)} icon={addOutline}/>*/}
                                            </IonItem>
                                            <IonCardContent>
                                                {
                                                    card && card.players && card.players.map((group: any, i: number) => {
                                                            return (group_item(card_index, i))
                                                        }
                                                    )
                                                }
                                            </IonCardContent>
                                        </IonCard>
                                    )
                                }
                            )
                        }
                    </Suspense>
                </IonContent>
                {
                    !event.final && authValues.isCoordinator &&
                    <IonFooter>
                        <IonToolbar>
                            <IonTitle>All Done?</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={handleDoneClick} routerDirection="back"
                                           routerLink={`/manage/details/${match.params.id}`}>Done</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonFooter>
                }
            </IonPage>
        )
            ;
    }
;

export default ManageCardsDoubles;
