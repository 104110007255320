import React from 'react';
import useFetch from "./fetch";


function useCourses(){
    const {status, data} = useFetch(`api/courses`, true);
    
    // handlers
    
    // debugger;
    return {data}
}

export default useCourses