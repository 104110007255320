import React, { useCallback } from 'react';
import { useFirestoreCollectionData, useFirestore } from 'reactfire';
import useTenantProvider from "./tenant-provider";

// Define the Event interface
interface Event {
    id: string;
    name: string;
    final: boolean;
    course_id: string;
    date: string;
    players: any;
}

function useCards(event_id: any) {
    const tenant = useTenantProvider();
    
    // Lazy load the Firestore SDK and create a reference to the cards subcollection
    const ref = useFirestore().collection(`tenants/${tenant}/event`).doc(event_id).collection('cards');

    // Subscribe to the cards collection and order them by the 'ordinal' field
    const { data: cards } = useFirestoreCollectionData(ref.orderBy('ordinal', 'asc'), { idField: 'id' });

    // Create handlers for various card operations
    const addCard = useCallback(async (card: any) => await ref.add(card), [ref]);

    const saveCard = useCallback(async (card: any) => {
        await ref.doc(card.id).set(card, { merge: true });
        const result = await ref.doc(card.id).get();

        // Delete cards that have no players in them
        if (result.data()?.players.length === 0) {
            await deleteCard(card.id);

            // Renumber cards after deleting one to ensure appropriate visualization
            cards
                .filter((x) => x.id !== card.id)
                .forEach((card: any, index: any) => {
                    card.ordinal = index;
                    card.name = `Card ${index + 1}`;
                    saveCard(card);
                });
        }
    }, [ref, cards]);

    const addPlayerToCard = useCallback(async (card_id: any, player: any) => await ref.doc(card_id).collection('players').add(player), [ref]);

    const deleteCard = useCallback(async (card_id: any) => await ref.doc(card_id).delete(), [ref]);

    const getCardDetails = useCallback(async (card_id: any) => ref.doc(card_id).collection('players'), [ref]);

    const removePlayerFromCard = useCallback(async (card_id: any, player_id: any) => await ref.doc(card_id).collection('players').doc(player_id).delete(), [ref]);

    // Combine all handlers into an object
    const handlers = {
        addCard,
        saveCard,
        getCardDetails,
        addPlayerToCard,
        deleteCard,
        removePlayerFromCard,
    };

    // Return an array of [data, handlers] to match hooks like useState
    return [cards, handlers];
}

export default useCards;
