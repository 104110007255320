import {IonBackButton, IonButtons, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import React from "react";

interface HeaderProps {
    name: string;
}

const HeaderContainer: React.FC<HeaderProps> = ({name}) => {
    return (
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton defaultHref={"/"} />
                </IonButtons>
                <IonTitle size="large">{name}</IonTitle>
            </IonToolbar>
        </IonHeader>
    );
};

export default HeaderContainer;
