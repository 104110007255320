import {
    IonAvatar,
    IonBackButton,
    IonButton,
    IonButtons,
    IonChip,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading, useIonToast
} from '@ionic/react';
import './Splash.css';
import React, {Suspense, useEffect, useState} from "react";
import {addCircleOutline, checkmarkCircleOutline, removeCircleOutline, save} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import useEvent from "../hooks/event-provider";
import {Pages} from "../types/enums";

interface ManageDetailsPageProps extends RouteComponentProps<{
    id: string;
}> {
}

const ManageDetails: React.FC<ManageDetailsPageProps> = ({match, history}) => {
        // const [events, handlers] = useEvents();
        const [{event, players, cards, ctps}, {saveField, finalize, postToFacebook}]: any = useEvent(match.params.id);
        const [scoresRecorded, setScoresRecorded] = useState<number>(0);
        const [scoresProcessed, setScoresProcessed] = useState<boolean>(false);
        const [present] = useIonAlert();
        const [presentLoader, dismissLoader] = useIonLoading();
        const [presentToast, dismissToast] = useIonToast();
        
        useEffect(() => {
            calculateScoresRecorded();
        }, [cards])

        const submitEventToServerHandler = () => {
            presentLoader({message: 'Submitting event data to the server...'});
            finalize(event, cards, ctps).then((response: any) => {

                saveField({'final': true})
                presentToast({
                    buttons: [ {text: 'dismiss', handler: () => dismissToast()}],
                    message: 'Successfully submitted the even to the server!',
                    duration: 10000
                })
            }).catch((error: any) => {
                presentToast({
                    buttons: [ {text: 'dismiss', handler: () => dismissToast()}],
                    message: `There was an error submitting the event data to the server. Reason: ${error.response.data}`,
                    duration: 10000
                })
            }).finally(() => {
                dismissLoader();
            })
        }
        
        const do_facebook_post = () => {
            postToFacebook(event, cards, ctps).then((response:any) => {
                saveField({'postedToFacebook': true})
                presentToast({
                    buttons: [ {text: 'dismiss', handler: () => dismissToast()}],
                    message: 'Event results posted to Facebook!',
                    duration: 10000
                })
            }).catch((error: any) => {
                presentToast({
                    buttons: [ {text: 'dismiss', handler: () => dismissToast()}],
                    message: `There was an error posting to Facebook. Reason: ${error.response.data}`,
                    duration: 10000
                })
            }).finally(() => {
                dismissLoader();
            })
        }

    const allow_event_finalize = () => {
            return event.managePlayersProcessed === true
                && event.manageCardsProcessed === true
                && event.manageHoleAssignmentsProcessed === true
                && event.manageCtpsProcessed === true
                // && event.manageNotesProcessed === true
                && event.managePayoutsProcessed === true
                && scoresRecorded == scoresRequired()
        }

        const manage_players_link = `/manage/players/${event.id}`;
        const manage_cards_link = `/manage/cards/${event.id}`;
        const manage_doubles_cards_link = `/manage/doublescards/${event.id}`;
        const manage_hole_assignments_link = `/manage/holeassignments/${event.id}`;
        const manage_hole_assignments_doubles_link = `/manage/holeassignmentsdoubles/${event.id}`;
        const manage_ctps_link = `/manage/ctps/${event.id}`;
        // const manage_notes_link = `/manage/notes/${event.id}`;
        const manage_scores_link = `/manage/scores/${event.id}`;
        const manage_payouts_link = `/manage/payouts/${event.id}`;
        const event_final_style = {
            fontSize: '2.5em',
            color: 'green'
        }
        const event_dirty_style = {
            fontSize: '2.5em',
            color: 'red'
        }
        const event_clean_style = {
            fontSize: '2.5em',
            color: 'gray'
        }
        const holeAssignments = () => {
            // @ts-ignore
            const holesAssigned = cards.filter(x => x.holeAssignment);
            if (holesAssigned.length == cards.length && event.manageHoleAssignmentsProcessed == "processing") {
                return (<IonIcon style={event_dirty_style} icon={removeCircleOutline}/>)
            } else if (holesAssigned.length == cards.length && event.manageHoleAssignmentsProcessed) {
                return (<IonIcon style={event_final_style} icon={checkmarkCircleOutline}/>)
            } else if (holesAssigned.length > 0 && holesAssigned.length != cards.length) {
                return (<IonIcon style={event_dirty_style} icon={removeCircleOutline}/>)
            } else if (holesAssigned.length == 0) {
                return (<IonIcon style={event_clean_style} icon={addCircleOutline}/>)
            }
        }
        const holeAssignmentsCount = () => {
            // @ts-ignore
            const holesAssigned = cards.filter(x => x.holeAssignment);
            return holesAssigned.length;
        }
        const calculateScoresRecorded = () => {
            let scoreCount = 0;
            for (let i = 0; i < cards.length; i++) {
                cards[i].players.forEach((player: any, index: number) => {
                    if (player.rawScore) {
                        scoreCount++;
                    }
                })
            }
            setScoresRecorded(scoreCount);
        }
        const manageFieldProcessed = (page: Pages) => {
            let fieldValue;
            switch (page) {
                case Pages.players:
                    fieldValue = event.managePlayersProcessed;
                    break;
                case Pages.cards:
                    fieldValue = event.manageCardsProcessed;
                    break;
                case Pages.holeAssignments:
                    fieldValue = event.manageHoleAssignmentsProcessed;
                    break;
                case Pages.ctps:
                    fieldValue = event.manageCtpsProcessed;
                    break;
                // case Pages.notes:
                //     fieldValue = event.manageNotesProcessed;
                //     break;
                case Pages.scores:
                    fieldValue = event.manageScoresProcessed;
                    break;
                case Pages.payouts:
                    fieldValue = event.managePayoutsProcessed;
                    break;
            }

            if (fieldValue && fieldValue == "processing") {
                return (<IonIcon style={event_dirty_style} icon={removeCircleOutline}/>)
            } else if (fieldValue) {
                return (<IonIcon style={event_final_style} icon={checkmarkCircleOutline}/>)
            } else {
                return (<IonIcon style={event_clean_style} icon={addCircleOutline}/>)
            }
        }
        
        const scoresRequired = () => {
            if (event.type === "Doubles"){
                return Math.ceil(players.length / 2);
            }
            return players.length;
        }

        const manageScoresProcessed = () => {
            // Give them the 'needs to be addressed' icon if no scores have been recorded
            if (scoresRecorded == 0){
                return (<IonIcon style={event_clean_style} icon={addCircleOutline}/>)
            }
            
            if (event.type === "Doubles") {
                if (scoresRecorded > 0 && scoresRequired() !== scoresRecorded) {
                    return (<IonIcon style={event_dirty_style} icon={removeCircleOutline}/>)
                } else if (scoresRecorded > 0 && scoresRequired() == Math.ceil(scoresRecorded / 2)) { 
                    return (<IonIcon style={event_final_style} icon={checkmarkCircleOutline}/>)
                } else {
                    return (<IonIcon style={event_clean_style} icon={addCircleOutline}/>)
                }
            }

            if (scoresRecorded > 0 && scoresRequired() !== scoresRecorded) {
                return (<IonIcon style={event_dirty_style} icon={removeCircleOutline}/>)
            } else if (scoresRecorded > 0 && scoresRequired() == scoresRecorded) {
                return (<IonIcon style={event_final_style} icon={checkmarkCircleOutline}/>)
            } else {
                debugger;
                return (<IonIcon style={event_clean_style} icon={addCircleOutline}/>)
            }
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonButtons slot="start">
                            <IonBackButton defaultHref={"/manage"}/>
                        </IonButtons>
                        <IonTitle size="large">{event.name}</IonTitle>
                        <IonText className="ion-padding-end"
                                 slot="end">{new Date(event.date).toLocaleDateString()}</IonText>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding" fullscreen>
                    <Suspense fallback={'loading event details...'}>
                        {
                            event.postedToFacebook && 
                                <IonTitle className="ion-text-center">Posted to Facebook</IonTitle>
                        }
                        {
                            !event.postedToFacebook && event.final &&
                            <IonButton
                                expand="block"
                                onClick={() =>
                                    present({
                                        header: 'Share on Facebook',
                                        message: 'This will post the event data to Facebook.',
                                        buttons: [
                                            'Cancel',
                                            { text: 'POST', handler: do_facebook_post },
                                        ],
                                        onDidDismiss: (e) => console.log('did dismiss'),
                                    })}>Post To Facebook</IonButton>
                        }
                        {
                            !event.final && allow_event_finalize() &&
                                <IonButton
                                    expand="block"
                                    onClick={() =>
                                        present({
                                            header: 'Are you sure?',
                                            message: 'This will submit the event data to the server and lock the event. This can only be undone on the server.',
                                            buttons: [
                                                'Cancel',
                                                { text: 'SUBMIT', handler: submitEventToServerHandler },
                                            ],
                                            onDidDismiss: (e) => console.log('did dismiss'),
                                        })}>Submit To Server</IonButton>
                        }
                        {
                            !event.final && !allow_event_finalize() &&
                                <IonTitle className="ion-text-center">Complete all items</IonTitle>
                        }
                        <IonList>
                            <IonItem routerLink={manage_players_link}>
                                <IonAvatar slot="start">
                                    {manageFieldProcessed(Pages.players)}
                                </IonAvatar>
                                <IonLabel>&nbsp;Players</IonLabel>
                                {
                                    players && players.length > 0 &&
                                    <IonChip>
                                        {players && players.length}
                                    </IonChip>
                                }
                            </IonItem>
                            {
                                (event.type === "Handicaps" || event.type === "Scratch") &&
                                <IonItem routerLink={manage_cards_link}>
                                    <IonAvatar slot="start">
                                        {manageFieldProcessed(Pages.cards)}
                                    </IonAvatar>
                                    <IonLabel>&nbsp;Cards</IonLabel>
                                    {
                                        cards && cards.length > 0 &&
                                        <IonChip>
                                            {cards && cards.length}
                                        </IonChip>
                                    }
                                </IonItem>
                            }
                            {
                                event.type === "Doubles" &&
                                <IonItem routerLink={manage_doubles_cards_link}>
                                    <IonAvatar slot="start">
                                        {manageFieldProcessed(Pages.cards)}
                                    </IonAvatar>
                                    <IonLabel>&nbsp;Cards</IonLabel>
                                    {
                                        cards && cards.length > 0 &&
                                        <IonChip>
                                            {cards && cards.length}
                                        </IonChip>
                                    }
                                </IonItem>
                            }
                            <IonItem routerLink={manage_hole_assignments_link}>
                                <IonAvatar slot="start">
                                    {holeAssignments()}
                                </IonAvatar>
                                <IonLabel>&nbsp;Hole Assignments</IonLabel>
                                {
                                    cards && holeAssignmentsCount() > 0 &&
                                    <IonChip>
                                        {cards && holeAssignmentsCount()}
                                    </IonChip>
                                }
                            </IonItem>
                            <IonItem routerLink={manage_ctps_link}>
                                <IonAvatar slot="start">
                                    {manageFieldProcessed(Pages.ctps)}
                                </IonAvatar>
                                <IonLabel>&nbsp;CTPs</IonLabel>
                                {ctps && ctps.length > 0 &&
                                <IonChip>
                                    Hole {ctps && ctps.map((ctp: any, index: number) => {
                                        return ctp.hole
                                    }
                                ).join(", ")
                                }
                                </IonChip>

                                }
                            </IonItem>
                            {/*<IonItem routerLink={manage_notes_link}>*/}
                            {/*    <IonAvatar slot="start">*/}
                            {/*        {manageFieldProcessed(Pages.notes)}*/}
                            {/*    </IonAvatar>*/}
                            {/*    <IonLabel>&nbsp;Notes</IonLabel>*/}
                            {/*</IonItem>*/}
                            <IonItem routerLink={manage_scores_link}>
                                <IonAvatar slot="start">
                                    {manageScoresProcessed()}
                                </IonAvatar>
                                <IonLabel>&nbsp;Scores</IonLabel>
                                {scoresRecorded > 0 && <IonChip>{scoresRecorded}</IonChip>}
                            </IonItem>
                            <IonItem routerLink={manage_payouts_link}>
                                <IonAvatar slot="start">
                                    {manageFieldProcessed(Pages.payouts)}
                                </IonAvatar>
                                <IonLabel>&nbsp;Results & Payouts</IonLabel>
                            </IonItem>
                        </IonList>
                    </Suspense>
                </IonContent>
            </IonPage>
        );
    }
;

export default ManageDetails;
