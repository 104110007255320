const useTenantProvider = () => {
    let hostName = null;
    const hostnameArray = window.location.hostname.split('.');
    if (hostnameArray.length > 2) {
        hostName = hostnameArray[0];
    }

    return hostName;
};

export default useTenantProvider;
