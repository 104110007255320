import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { FirebaseAppProvider } from 'reactfire';
import {AuthProvider} from "./auth-context";

var firebaseConfig = {
    apiKey: "AIzaSyAdHeHjDcDgo9mk5S3MMy7frGw5E4Gm1b8",
    authDomain: "dgceventapp.firebaseapp.com",
    projectId: "dgceventapp",
    storageBucket: "dgceventapp.appspot.com",
    messagingSenderId: "762445258171",
    appId: "1:762445258171:web:4c43f4c4e003f13083389e",
    measurementId: "G-6S8SJDPVVJ"
};

ReactDOM.render(
  <React.StrictMode>
      <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
          <AuthProvider>
              <App/>
          </AuthProvider>
      </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
