import React, {useCallback} from 'react';
import useFetch from "./fetch";
import axios from "axios";
import firebase from "firebase";


function useSchedule() {
    const url = `api/schedule`;
    const {status, data} = useFetch(url, false);
    const api_schedule = data;

    // handlers
    async function addPlayer(playerData: any) {
        const userToken = await firebase.auth().currentUser?.getIdToken();
        const obj = {
            'firstName': playerData.firstName,
            'lastName': playerData.lastName
        }
        const requestOptions = {
            headers: {'Content-Type': 'application/json', 'Authorization': userToken}
        };
        const result = await axios.post(`api/players`, obj, requestOptions)
        return result.data;
    }

    const handlers = {
        // addNewPlayer: useCallback(addPlayer, [])
    }

    return [{api_schedule}, handlers];
}

export default useSchedule